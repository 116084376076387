import React from 'react';
export default {
  'cart': {
    'change-currency': 'मुद्रा बदलें',
    'and-then': 'और फिर',
    'plus-tax': ' + कर',
    'for': 'के लिए',
    'edit': {
      'label': 'बदलाव करें',
      'submit': 'लागू करें'
    },
    'empty': 'आपने अभी तक अपने ऑर्डर में कुछ भी नहीं जोड़ा है.',
    'free': 'नि:शुल्क',
    'promo-code': {
      'add': 'गिफ्ट कार्ड या प्रोमो कोड जोड़ें',
      'label': 'गिफ्ट कार्ड या प्रोमो कोड',
      'submit': 'लागू करें'
    },
    'subtotal-line': {
      'tax-label': 'अनुमानित कर',
      'subtotal': 'उप कुल',
      'tax': 'टैक्स',
      'gift-card': 'उपहार पत्र'
    },
    'starting-price': 'अंकित मूल्य',
    'title': 'आदेश सारांश',
    'setup-fee': 'सेटअप शुल्क',
    'free-trial': 'मुफ्त आज़माइश',
    'total-line': {
      'label': 'कुल'
    },
    'order-total-line': {
      'label': 'ऑर्डर टोटल'
    }
  },
  'address': {
    'country': 'देश',
    'address2': 'अपार्टमेंट, सुइट, आदि.',
    'tax-identifier': 'कर पहचानकर्ता',
    'tax-identifier-type': 'कर पहचानकर्ता प्रकार',
    'first-name': 'पहला नाम',
    'last-name': 'अंतिम नाम',
    'company-name': 'कंपनी का नाम',
    'address1': 'पता',
    'city': 'शहर',
    'state': 'प्रांत',
    'postal-code': 'जिप/पिन',
    'phone-number': 'फ़ोन नंबर',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'कार्ड का विवरण',
      'expiry': 'महीना/साल',
      'name': 'क्रेडिट कार्ड',
      'number': 'क्रेडिट कार्ड संख्या ',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'बैंक खाता',
      'routing-number': 'राउटिंग नम्बर',
      'account-number': 'खाता संख्या',
      'account-number-confirmation': 'खाता संख्या पुष्टि करें',
      'account-type': {
        'label': 'खाते का प्रकार',
        'checking': 'खाते की जांच',
        'savings': 'बचत खाता'
      },
      'name-on-account': 'खाते पर मौजूद नाम'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'बिल भेजने का पता',
  'billing-address-same-as-shipping': 'शिपिंग पते के समान ही',
  'submit': 'अब भुगतान करें',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} चुना गया</>,
  'express-checkout': 'एक्सप्रेस चेकआउट',
  'change-payment-method': 'भुगतान विधि को बदलें',
  'day': 'दिन',
  'days': 'दिनों',
  'week': 'हफ़्ता',
  'weeks': 'हफ़्ते',
  'month': 'महीना',
  'months': 'महीने',
  'year': 'वर्ष',
  'years': 'वर्षों',
  'confirmation': {
    'title': 'ऑर्डर करने के लिए धन्यवाद',
    'order-num': ({ number }) => <>ऑर्डर #{number}</>,
    'fulfilled-on': ({ date }) => <>{date} को पूरा हुआ</>,
    'email-notice': 'ऑर्डर प्लेस हो जाने पर आपको एक ईमेल कन्फर्मेशन प्राप्त होगा.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>{name} पर वापिस जाएँ</>,
    'credit-applied': 'खाते में जमा'
  },
  'back': 'वापस',
  'contact-info': 'संपर्क करने संबंधी जानकारी',
  'email-address': 'ईमेल',
  'payment-info': 'बिलिंग जानकारी',
  'shipping-address': 'शिपिंग पता',
  'legal': {
    'accept': 'मैं स्वीकार करता हूँ',
    'privacy-policy': 'गोपनीयता नीति',
    'terms-of-service': 'सेवा की शर्तें'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' और ',
      'lastWordConnector': ', और '
    }
  },
  'powered-by': ({ recurly }) => <>सदस्यता बिलिंग {recurly} द्वारा संचालित है</>,
  'errors': {},
  'subscribe': 'सदस्यता लें'
};
