import React from 'react';

export function ApplePayLogoImage ({
  className = ''
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="51" height="22" viewBox="0 0 51 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ className }}>
      <path d="M10.398 2.79902C10.9865 2.06296 11.3859 1.07464 11.2805 0.0646973C10.4191 0.107533 9.36784 0.633029 8.75922 1.36967C8.21275 2.00049 7.72907 3.03019 7.85517 3.99779C8.8222 4.08167 9.78833 3.51443 10.398 2.79902Z" fill="white"/>
      <path d="M11.2695 4.18669C9.86516 4.10304 8.67111 4.98373 8.00046 4.98373C7.32944 4.98373 6.30245 4.22885 5.19168 4.2492C3.74594 4.27043 2.40446 5.08786 1.67081 6.38796C0.161814 8.9888 1.27259 12.8468 2.74 14.965C3.45262 16.013 4.31143 17.1668 5.44306 17.1254C6.51225 17.0834 6.93133 16.4331 8.23099 16.4331C9.52968 16.4331 9.90719 17.1254 11.039 17.1044C12.2127 17.0834 12.9465 16.0559 13.6591 15.007C14.4766 13.8123 14.8112 12.6588 14.8323 12.5955C14.8112 12.5745 12.569 11.7144 12.5483 9.13515C12.5271 6.97553 14.3086 5.94829 14.3925 5.88459C13.3864 4.39663 11.8145 4.22885 11.2695 4.18669Z" fill="white"/>
      <path d="M23.4976 1.26404C26.55 1.26404 28.6754 3.36805 28.6754 6.43133C28.6754 9.50555 26.5062 11.6205 23.4211 11.6205H20.0416V16.9949H17.5999V1.26404H23.4976V1.26404ZM20.0416 9.57094H22.8433C24.9691 9.57094 26.1791 8.42642 26.1791 6.44226C26.1791 4.45832 24.9691 3.32452 22.8542 3.32452H20.0416V9.57094Z" fill="white"/>
      <path d="M29.3135 13.7354C29.3135 11.7294 30.8506 10.4976 33.5762 10.345L36.7156 10.1597V9.27677C36.7156 8.00124 35.8543 7.23816 34.4156 7.23816C33.0526 7.23816 32.2022 7.89211 31.9953 8.91699H29.7715C29.9022 6.84557 31.6681 5.3194 34.5026 5.3194C37.2825 5.3194 39.0593 6.79111 39.0593 9.09131V16.9949H36.8026V15.1089H36.7484C36.0835 16.3845 34.6334 17.1911 33.1291 17.1911C30.8834 17.1911 29.3135 15.7957 29.3135 13.7354ZM36.7156 12.6998V11.795L33.892 11.9693C32.4857 12.0675 31.69 12.6889 31.69 13.67C31.69 14.6728 32.5185 15.327 33.7831 15.327C35.4291 15.327 36.7156 14.1932 36.7156 12.6998Z" fill="white"/>
      <path d="M41.1897 21.2139V19.3061C41.3638 19.3496 41.7562 19.3496 41.9526 19.3496C43.0427 19.3496 43.6314 18.8918 43.991 17.7145C43.991 17.6926 44.1983 17.0168 44.1983 17.0059L40.0559 5.52655H42.6065L45.5066 14.8583H45.55L48.4501 5.52655H50.9355L46.64 17.5944C45.6593 20.3745 44.5255 21.2683 42.149 21.2683C41.9526 21.2683 41.3638 21.2465 41.1897 21.2139Z" fill="white"/>
    </svg>
  );
}
