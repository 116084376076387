import React, { useEffect, useState } from 'react';
import { useLocale } from '@/hooks/use-locale';
import { StreetAddress } from './street-address';
import { Field, TextInput } from '../form';
import { CheckoutAddressProps } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME, autoCompleteValueFor, nameFor } from '@/utils/address-utils';

export function FullAddress ({
  autoCompletePrefix,
  namePrefix = '',
  onChange = () => {},
  required = [],
  value
}: CheckoutAddressProps) {
  const { t } = useLocale();
  const [city, setCity] = useState(value?.city || '');
  const [state, setState] = useState(value?.state || '');

  useEffect(() => setCity(value?.city || ''), [value?.city]);
  useEffect(() => setState(value?.state || ''), [value?.state]);

  return (
    <StreetAddress {...{ autoCompletePrefix, namePrefix, onChange, required, value }}>
      <Field
        label={t('address.city')}
        name={nameFor(ADDRESS_FIELD_NAME.CITY, namePrefix)}
        className="col-span-2"
        required={required.includes(ADDRESS_FIELD_NAME.CITY)}
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-level2', autoCompletePrefix)}
          onBlur={() => onChange({ city })}
          onChange={event => setCity(event.target.value)}
          value={city}
        />
      </Field>
      <Field
        label={t('address.state')}
        name={nameFor(ADDRESS_FIELD_NAME.STATE, namePrefix)}
        required={required.includes(ADDRESS_FIELD_NAME.STATE)}
        className="col-span-2 xs:col-span-1"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-level1', autoCompletePrefix)}
          onBlur={() => onChange({ state })}
          onChange={event => setState(event.target.value)}
          value={state}
        />
      </Field>
    </StreetAddress>
  );
}
