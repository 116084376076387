import React from 'react';
export default {
  'cart': {
    'change-currency': '更改货币',
    'and-then': '然后',
    'plus-tax': ' + 税',
    'for': '为期',
    'edit': {
      'label': '编辑',
      'submit': '应用'
    },
    'empty': '您还没有在订单中添加任何商品。',
    'free': '免费',
    'promo-code': {
      'add': '添加礼品卡或促销代码',
      'label': '礼品卡或促销代码',
      'submit': '应用'
    },
    'subtotal-line': {
      'tax-label': '估计税费',
      'subtotal': 'Subtotal',
      'tax': 'Tax',
      'gift-card': '礼物卡'
    },
    'starting-price': '起始价',
    'title': 'Order Summary',
    'setup-fee': 'setup fee',
    'free-trial': '免费试用',
    'total-line': {
      'label': 'Total'
    },
    'order-total-line': {
      'label': 'Order Total'
    }
  },
  'address': {
    'country': '国家',
    'address2': '公寓、套房等',
    'tax-identifier': '税务识别符',
    'tax-identifier-type': '税务识别符类型',
    'first-name': '名字',
    'last-name': '姓',
    'company-name': 'Company Name',
    'address1': 'Street Address',
    'city': 'City',
    'state': '省',
    'postal-code': 'Zip/Postal',
    'phone-number': '电话号码',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': '银行卡详细信息',
      'expiry': '月月/年年',
      'name': 'Credit Card',
      'number': 'Card Number',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Bank Account',
      'routing-number': 'Routing Number',
      'account-number': 'Account Number',
      'account-number-confirmation': 'Confirm Account Number',
      'account-type': {
        'label': 'Account Type',
        'checking': 'Checking',
        'savings': 'Savings'
      },
      'name-on-account': 'Name on Account'
    },
    'amazon': {
      'name': 'Amazon Pay'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': '账单地址',
  'billing-address-same-as-shipping': '与收货地址相同',
  'submit': '立即付款',
  'payment-method-selected': ({ paymentMethod }) => <>已选择{paymentMethod}</>,
  'express-checkout': '快速结账',
  'change-payment-method': '更改支付方式',
  'day': '天',
  'days': '天',
  'week': '周',
  'weeks': '周',
  'month': '月',
  'months': '月',
  'year': '年',
  'years': '年',
  'confirmation': {
    'title': '感谢您的预订',
    'order-num': ({ number }) => <>订单 #{number}</>,
    'fulfilled-on': ({ date }) => <>完成于{date}</>,
    'email-notice': '您将收到一封有关您订单的确认电子邮件。',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Return to {name}</>,
    'credit-applied': 'Credit Applied'
  },
  'back': '返回',
  'contact-info': 'Contact Information',
  'email-address': '电子邮件',
  'payment-info': 'Billing Information',
  'shipping-address': 'Shipping Address',
  'legal': {
    'accept': 'I accept the',
    'privacy-policy': 'Privacy Policy',
    'terms-of-service': 'Terms of Service'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' 和 ',
      'lastWordConnector': ', 和 '
    }
  },
  'powered-by': ({ recurly }) => <>Subscription billing powered by {recurly}</>,
  'errors': {},
  'subscribe': 'Subscribe'
};
