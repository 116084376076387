import { AutoCompletePrefix } from '@/types/checkout-address';

export const ADDRESS_FIELD_NAME = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  COMPANY: 'company',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
  PHONE: 'phone',
  VAT_NUMBER: 'vat_number',
  TAX_IDENTIFIER: 'tax_identifier',
  TAX_IDENTIFIER_TYPE: 'tax_identifier_type'
};

export const nameFor = (name: string, prefix?: string) => prefix ? `${prefix}[${name}]` : name;

export const autoCompleteValueFor = (name: string, prefix?: AutoCompletePrefix) => prefix ? `${prefix} ${name}` : name;
