import React, { useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { CartSubscription } from '@/services/session';
import { CartContext } from '@/contexts/cart-context';
import { CartInternalContext } from '@/contexts/cart-internal-context';
import { CartItemSubscriptionAddOn } from './cart-item-subscription-addon';
import { CartItem } from './cart-item';
import { NumericInput } from '@/components/form';
import { Price } from '@/components/price';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';

export function CartItemSubscription ({
  onSelect,
  selected,
  subscription
}: {
  onSelect?: () => void;
  selected?: boolean;
  subscription: CartSubscription;
}) {
  const {
    addOns,
    ephemeralId,
    quantity,
    quantityMin,
    quantityMax,
    quantityMutable
  } = subscription;
  const [{ cart }, updateCheckoutSession] = useCheckoutSession();
  const { pricing } = useContext(CartContext);
  const { currency, showCartEdits, soleTrialSubscription } = useContext(CartInternalContext);
  const subscriptionPricing = pricing.items.subscriptions.find(s => s.id === ephemeralId);
  const { t } = useLocale();

  if (!subscriptionPricing) return;

  const plan = subscriptionPricing.items.plan;
  const { price } = subscriptionPricing;

  return (
    <>
      <CartItem
        period={plan.period}
        prependText={plan.pricing_model === 'ramp' ? t('cart.starting-price') : ''}
        price={{ currency, amount: price.base.plan.unit * Number(quantity) }}
        priceClassName={twMerge('font-bold', plan.trial && 'line-through')}
        quantity={Number(quantity)}
        title={<span className="font-bold">{plan.name}</span>}
        testGroupId='subscription'
        {...{ onSelect, selected }}
      >
        <div className="mt-2 pl-4 border-l-2 border-gray-300 text-sm leading-6 text-checkout-subtle">
          {!soleTrialSubscription && plan.trial && (
            <p>{plan.trial.length} {plan.trial.interval} {t('cart.free-trial')}</p>
          )}
          {showCartEdits && quantityMutable && (
            <div>
              <NumericInput
                setValue={value => {
                  const subscriptions = JSON.parse(JSON.stringify(cart.subscriptions));

                  subscriptions
                    .find(s => s.ephemeralId === ephemeralId)
                    .quantity = value;

                  updateCheckoutSession({ cart: { subscriptions } });
                }}
                min={quantityMin}
                max={quantityMax}
                value={quantity}
              />
              &nbsp;
              x <Price currency={currency} amount={price.base.plan.unit} />
            </div>
          ) || (
            (Number(quantity) > 1 || quantityMutable) && (
              <p>
                {quantity} x <Price currency={currency} amount={price.base.plan.unit} />
              </p>
            )
          )}
        </div>
        {price.now.setup_fee > 0 && (
          <CartItem
            price={{ currency, amount: subscriptionPricing.price.now.setup_fee }}
            quantity={1}
            title={t('cart.setup-fee')}
            testGroupId='setup-fee'
            associated
          />
        )}
        {addOns.map((addOn, index) => (
          <CartItemSubscriptionAddOn
            cartAddOn={addOn}
            subscription={subscription}
            currency={currency}
            subscriptionPricing={subscriptionPricing}
            key={index}
          />
        ))}
      </CartItem>
    </>
  );
}
