import React from 'react';

import { useLocale } from '@/hooks/use-locale';

export type Currency = {
  symbol?: string;
  code: string;
};

export type PriceObject = {
  currency: Currency;
  amount: string;
};

export function Price ({
  currency,
  amount
}: PriceObject) {
  const { locale } = useLocale();

  const formattedAmount = new Intl.NumberFormat(
    locale.baseName,
    {
      style: 'currency',
      currency: currency.code,
    }
  ).format(Number(amount));

  return <>{formattedAmount}</>;
}
