import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { RecurlyWithInternals } from '@/types/recurly';
import { useRecurly } from '@recurly/react-recurly';
import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { CartContext } from '@/contexts/cart-context';
import { useLocale } from '@/hooks/use-locale';
import { Button, Field, FieldError, TextInput } from '@/components/form';
import { RecurlyError } from '@recurly/recurly-js';
import { CartInternalContext } from '@/contexts/cart-internal-context';

export function PromoCodes () {
  const recurly = useRecurly() as RecurlyWithInternals;
  const promoCodeRef = useRef();
  const [promoCodeEntry, setPromoCodeEntry] = useState('');
  const [showPromoCodeEntry, setShowPromoCodeEntry] = useState(false);
  const [_, updateCheckoutSession] = useCheckoutSession();
  const { pricing } = useContext(CartContext);
  const { error, setError, canApplyPromoCode, setCanApplyPromoCode } = useContext(CartInternalContext);
  const { t } = useLocale();

  const handleApplyPromoCode = (newPromoCode: string) => {
    if (!newPromoCode) return setError({ promoCode: new FieldError('promoCode', ['']) });
    setCanApplyPromoCode(false);

    recurly.coupon({
      coupon: newPromoCode,
      plans: pricing?.subscriptionPlanCodes
    }, (err: RecurlyError, _) => {
      if (err) {
        recurly.giftCard({ code: newPromoCode }, (err) => {
          if (err) {
            setError({ promoCode: new FieldError('promoCode', ['']) });
          } else {
            updateCheckoutSession({ cart: { giftCards: [{ code: newPromoCode }] } });
            setShowPromoCodeEntry(false);
          }
          setCanApplyPromoCode(true);
        });
      } else {
        updateCheckoutSession({ cart: { coupons: [{ code: newPromoCode }] } });
        setShowPromoCodeEntry(false);
      }
    });
  };

  if (showPromoCodeEntry) {
    return (
      <div className="my-2 py-2">
        <Field
          className="flex-grow"
          error={error?.promoCode}
          label={t('cart.promo-code.label')}
          name="cart[coupons]"
        >
          <div className="flex items-end">
            <TextInput
              autoComplete="off"
              onChange={(event: ChangeEvent) => {
                 
                const { promoCode: _, ...rest } = error;
                setError(rest);
                setPromoCodeEntry((event.target as HTMLInputElement)?.value);
              }}
              onKeyUp={event => {
                if (event.keyCode === 27) {
                  setShowPromoCodeEntry(false);
                }
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  handleApplyPromoCode(promoCodeEntry);
                  event.preventDefault();
                  return false;
                }
              }}
              ref={promoCodeRef}
            />
            <Button
              className="m-0 ml-2 lg:px-6 whitespace-nowrap"
              processing={!canApplyPromoCode}
              onClick={event => {
                event.preventDefault();
                handleApplyPromoCode(promoCodeEntry);
              }}
            >
              {t('cart.promo-code.submit')}
            </Button>
          </div>
        </Field>
      </div>
    );
  } else if (!(pricing?.items?.coupon || pricing?.items?.giftCard)) {
    return (
      <div className="my-2 py-2">
        <a
          className="col-span-4"
          href="#"
          onClick={event => {
            event.preventDefault();
            setShowPromoCodeEntry(true);
            setTimeout(() => promoCodeRef?.current?.focus(), 0);
          }}
        >
          {t('cart.promo-code.add')}
        </a>
      </div>
    );
  }
}
