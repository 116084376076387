import React from 'react';
export default {
  'cart': {
    'change-currency': '通貨の変更',
    'and-then': 'その後',
    'plus-tax': ' + 税',
    'for': '期間',
    'edit': {
      'label': '編集',
      'submit': '適用'
    },
    'empty': 'ご注文にまだ何も追加されていません。',
    'free': '無料',
    'promo-code': {
      'add': 'ギフトカードまたはプロモコードを入力',
      'label': 'ギフトカードまたはプロモコード',
      'submit': '適用'
    },
    'subtotal-line': {
      'tax-label': '推計の税額',
      'subtotal': '小計',
      'tax': '税金',
      'gift-card': 'ギフトカード'
    },
    'starting-price': '開始価格',
    'title': 'オーダー要約',
    'setup-fee': 'セットアップ料金',
    'free-trial': '無料トライアル',
    'total-line': {
      'label': '合計'
    },
    'order-total-line': {
      'label': '注文の合計'
    }
  },
  'address': {
    'country': '国',
    'address2': 'マンション、アパートなど。',
    'tax-identifier': '税識別子',
    'tax-identifier-type': '税識別子タイプ',
    'first-name': '名',
    'last-name': '姓',
    'company-name': '会社名',
    'address1': '所在地住所',
    'city': '市町村',
    'state': '州',
    'postal-code': '郵便番号',
    'phone-number': '電話番号',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'カード情報',
      'expiry': '月/年',
      'name': 'クレジットカード',
      'number': 'クレジットカード番号',
      'cvv': 'セキュリティコード（CVV）',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': '銀行口座',
      'routing-number': '銀行コード',
      'account-number': '口座番号',
      'account-number-confirmation': '口座番号の確認',
      'account-type': {
        'label': '口座の種類',
        'checking': '当座預金口座',
        'savings': '普通預金口座'
      },
      'name-on-account': '口座名'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': '請求先住所',
  'billing-address-same-as-shipping': '配送先住所と同じ',
  'submit': '今すぐ支払う',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} が選択されました</>,
  'express-checkout': 'エクスプレスチェックアウト',
  'change-payment-method': '支払い方法を変更',
  'day': '日',
  'days': '日',
  'week': '週',
  'weeks': '週',
  'month': '月',
  'months': '月',
  'year': '年',
  'years': '年',
  'confirmation': {
    'title': 'ご注文いただきありがとうございます',
    'order-num': ({ number }) => <>注文 #{number}</>,
    'fulfilled-on': ({ date }) => <>{date}に完了</>,
    'email-notice': 'ご注文の確認メールをお送りします。',
    'payment': 'Payment',
    'return-to': ({ name }) => <>{name}に戻る</>,
    'credit-applied': 'クレジット適用'
  },
  'back': '戻る',
  'contact-info': '連絡先情報',
  'email-address': 'Eメール',
  'payment-info': '請求先情報',
  'shipping-address': '運送住所',
  'legal': {
    'accept': '次のことに同意します',
    'privacy-policy': 'プライバシー方針',
    'terms-of-service': 'サービス利用規約'
  },
  'support': {
    'array': {
      'wordsConnector': 'と',
      'twoWordsConnector': 'と',
      'lastWordConnector': 'と'
    }
  },
  'powered-by': '購読料の請求は{recurly}によって行われます。',
  'errors': {},
  'subscribe': '購読する'
};
