import { Dispatch, SetStateAction, createContext } from 'react';
import { SetCheckoutPricing, Subscription } from '@recurly/react-recurly';
import { Address, CheckoutPrice, CheckoutPricingInstance } from '@recurly/recurly-js';

export const CART_EMPTINESS_STATE = {
  NO_ITEMS: 0,
  ZERO_ITEMS: 1,
  NOT_EMPTY: 2
};

type CartContextType = {
  billingAddress?: Address;
  cartEmptiness?: number;
  price: CheckoutPrice;
  priceLoading: boolean;
  pricing?: CheckoutPricingInstance;
  requiresBillingInfo: boolean;
  setCartEmptiness: Dispatch<SetStateAction<number | undefined>>;
  setCheckoutPricing: SetCheckoutPricing;
  setRequiresBillingInfo: Dispatch<SetStateAction<boolean>>;
  shippingAddress?: Address;
  soleTrialSubscription?: Subscription;
}

export const CartContext = createContext<CartContextType>({
  price: {
    now: { adjustments: '', discount: '', giftCard: '', items: [], subscriptions: '', subtotal: '', taxes: '', total: '' },
    next: { adjustments: '', discount: '', giftCard: '', items: [], subscriptions: '', subtotal: '', taxes: '', total: '' },
    currency: { code: '' , symbol: '' },
    taxes: []
  },
  priceLoading: true,
  requiresBillingInfo: true,
  setCheckoutPricing: () => {},
  setCartEmptiness: () => {},
  setRequiresBillingInfo: () => {}
});
