import React, { useEffect, useState } from 'react';

import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';
import { Field, TextInput } from '../form';
import { FLAGS } from '@/services/session';
import { CheckoutAddressProps } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME, autoCompleteValueFor, nameFor } from '@/utils/address-utils';

export function PostalCodeAddress ({
  autoCompletePrefix,
  namePrefix = '',
  onChange = () => {},
  required = [],
  value
}: CheckoutAddressProps) {
  const [{ flags }] = useCheckoutSession();
  const { t } = useLocale();
  const [postalCode, setPostalCode] = useState(value?.postalCode || '');
  const [phone, setPhone] = useState(value?.phone || '');

  useEffect(() => setPostalCode(value?.postalCode || ''), [value?.postalCode]);
  useEffect(() => setPhone(value?.phone || ''), [value?.phone]);

  return (
    <>
      <Field
        label={t('address.postal-code')}
        name={nameFor(ADDRESS_FIELD_NAME.POSTAL_CODE, namePrefix)}
        required={required.includes(ADDRESS_FIELD_NAME.POSTAL_CODE)}
        className="col-span-2 xs:col-span-1"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('postal-code', autoCompletePrefix)}
          onBlur={() => onChange({ postalCode })}
          onChange={event => setPostalCode(event.target.value)}
          value={postalCode}
        />
      </Field>

      {flags.includes(FLAGS.ACCEPT_PHONE_NUMBER) && (
        <Field
          label={t('address.phone-number')}
          name={nameFor(ADDRESS_FIELD_NAME.PHONE, namePrefix)}
          required={required.includes(ADDRESS_FIELD_NAME.PHONE)}
          className="col-span-2"
        >
          <TextInput
            autoComplete={autoCompleteValueFor('tel', autoCompletePrefix)}
            onBlur={() => onChange({ phone })}
            onChange={event => setPhone(event.target.value)}
            value={phone}
          />
        </Field>
      )}
    </>
  );
}
