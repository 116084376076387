import React from 'react';
import { CssColor } from '@/types/html';

export function LoadingImage ({
  className,
  color = '#fff'
}: {
  className?: string;
  color: CssColor;
}) {
  return (
    <svg viewBox="0 0 100 100" focusable="false" className={className}>
      <path stroke={color} fill={color} strokeWidth="4" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path>
    </svg>
  );
}
