import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { Loading } from './empty-state';
import { Development } from './development';

export const ENVIRONMENTS = Object.freeze({
  PROD: 'production',
  STAGING: 'staging',
  QA: 'qa',
  DEVELOPMENT: 'development'
});

type Environment = {
  api: string;
  env: 'production' | 'staging' | 'qa' | 'development';
  loading: boolean;
};

export const EnvironmentContext = createContext<[Environment, Dispatch<SetStateAction<Environment>>]>([
  initialEnvironment(),
  () => {}
]);

export function EnvironmentProvider ({ children }: PropsWithChildren) {
  const [environment, setEnvironment] = useState(initialEnvironment);

  return (
    <EnvironmentContext.Provider value={[environment, setEnvironment]}>
      <Development />
      {environment.loading ? <Loading /> : children}
    </EnvironmentContext.Provider>
  );
}

function initialEnvironment (): Environment {
  let api = 'https://api.recurly.com/js/v1';
  PROD_EU: api = 'https://api.eu.recurly.com/js/v1';
  STAGING: api = 'https://api.staging2.recurlyqa.com/js/v1';
  STAGING_EU: api = 'https://api.eu.staging2.recurlyqa.com/js/v1';
  QA: api = 'https://api.qa2.recurlyqa.com/js/v1';
  DEV: api = 'https://app.recurly.dev:3000/js/v1';
  TEST: api = 'http://localhost:9877';

  let env = 'production';
  STAGING: env = 'staging';
  QA: env = 'qa';
  DEV: env = 'development';

  let loading = false;
  QA: loading = true;
  DEV: loading = true;

  return {
    api,
    env,
    loading
  };
}
