import React, { Dispatch, SetStateAction } from 'react';

import { useLocale } from '@/hooks/use-locale';
import { countryOptionsFor } from '@/services/locale';
import { Customer } from '@/services/purchase';

import { Field, SelectInput } from '../form';
import { FirstAndLastName } from './first-and-last-name';
import { TaxIdentifier } from './tax-identifier';
import { FullAddress } from './full-address';
import { StreetAddress } from './street-address';
import { PostalCodeAddress } from './postal-code-address';
import { CheckoutAddress } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME, nameFor } from '@/utils/address-utils';

type AddressFormProps = {
  address: CheckoutAddress | undefined;
  addressRequirement: string;
  customer: Customer | CheckoutAddress | undefined;
  namePrefix?: string;
  required: string[];
  setAddress: Dispatch<SetStateAction<CheckoutAddress>> | Dispatch<SetStateAction<CheckoutAddress | undefined>>;
  setCustomer: Dispatch<SetStateAction<Customer>> | Dispatch<SetStateAction<CheckoutAddress | undefined>>;
  showTaxIdentifierField: boolean;
}

export function AddressForm ({
  address,
  addressRequirement,
  customer,
  namePrefix = '',
  required,
  setAddress,
  setCustomer,
  showTaxIdentifierField,
}: AddressFormProps) {
  const { locale, t } = useLocale();

  return (
    <>
      {addressRequirement !== 'none' && (
        <Field
          label={t('address.country')}
          name={nameFor(ADDRESS_FIELD_NAME.COUNTRY, namePrefix)}
          className="col-span-2"
          required={required.includes('country')}
        >
          <SelectInput
            autoComplete="billing country"
            onChange={(event) =>
              setAddress({
                ...address,
                country: event.target.value,
              })
            }
            options={countryOptionsFor(locale)}
            value={address?.country}
          />
        </Field>
      )}
      <FirstAndLastName
        autoCompletePrefix="billing"
        namePrefix={namePrefix}
        onCustomerChange={(values) => setCustomer({ ...customer, ...values })}
        onAddressChange={(values) => setAddress({ ...address, ...values })}
      />
      {showTaxIdentifierField && (
        <TaxIdentifier
          address={address}
          onAddressChange={(values) =>
            setAddress({ ...address, ...values })
          }
        />
      )}
      {addressRequirement === 'full' && (
        <FullAddress
          autoCompletePrefix="billing"
          namePrefix={namePrefix}
          onChange={(values) =>
            setAddress({ ...address, ...values })
          }
          required={required}
        />
      )}
      {addressRequirement === 'streetzip' && (
        <StreetAddress
          autoCompletePrefix="billing"
          namePrefix={namePrefix}
          onChange={(values) =>
            setAddress({ ...address, ...values })
          }
          required={required}
        />
      )}
      {addressRequirement === 'zip' && (
        <PostalCodeAddress
          autoCompletePrefix="billing"
          namePrefix={namePrefix}
          onChange={(values) =>
            setAddress({ ...address, ...values })
          }
          required={required}
        />
      )}
    </>
  );
}
