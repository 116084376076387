import React from 'react';
export default {
  'cart': {
    'change-currency': 'Währung ändern',
    'and-then': 'und dann',
    'plus-tax': ' + MwSt.',
    'for': 'Für',
    'edit': {
      'label': 'Bearbeiten',
      'submit': 'Anwenden'
    },
    'empty': 'Sie haben noch nichts zu Ihrer Bestellung hinzugefügt.',
    'free': 'Kostenlos',
    'promo-code': {
      'add': 'Geschenkgutschein oder Aktionscode hinzufügen',
      'label': 'Geschenkgutschein oder Aktionscode',
      'submit': 'Anwenden'
    },
    'subtotal-line': {
      'tax-label': 'Geschätzte MwSt.',
      'subtotal': 'Zwischensumme',
      'tax': 'Steuern',
      'gift-card': 'Geschenkkarte'
    },
    'starting-price': 'Startpreis',
    'title': 'Bestellübersicht',
    'setup-fee': 'Einrichtungs-Gebühr',
    'free-trial': 'Kostenlose Testversion',
    'total-line': {
      'label': 'Gesamtbetrag'
    },
    'order-total-line': {
      'label': 'Gesamtauftrag'
    }
  },
  'address': {
    'country': 'Land',
    'address2': 'Wohnung, Appartement, usw.',
    'tax-identifier': 'Steuernummer',
    'tax-identifier-type': 'Steuernummertyp',
    'first-name': 'Vorname',
    'last-name': 'Nachname',
    'company-name': 'Firmenname',
    'address1': 'Straße und Hausnummer',
    'city': 'Stadt',
    'state': 'Bundesland',
    'postal-code': 'Postleitzahl',
    'phone-number': 'Telefonnummer',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Angaben zur Karte',
      'expiry': 'MM/JJ',
      'name': 'Kreditkarte',
      'number': 'Kreditkartennummer',
      'cvv': 'Prüfziffer',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Bankkonto',
      'routing-number': 'Bankleitzahl',
      'account-number': 'Kontonummer',
      'account-number-confirmation': 'Bestätigung Kontonummer',
      'account-type': {
        'label': 'Kontotyp',
        'checking': 'Girokonto',
        'savings': 'Sparkonto'
      },
      'name-on-account': 'Kontoninhaber'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Rechnungsadresse',
  'billing-address-same-as-shipping': 'Identisch mit der Lieferadresse',
  'submit': 'Jetzt bezahlen',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} ausgewählt</>,
  'express-checkout': 'Express-Kaufabwicklung',
  'change-payment-method': 'Zahlungsmethode ändern',
  'day': 'Tag',
  'days': 'Tage',
  'week': 'Woche',
  'weeks': 'Wochen',
  'month': 'Monat',
  'months': 'Monate',
  'year': 'Jahr',
  'years': 'Jahre',
  'confirmation': {
    'title': 'Vielen Dank für Ihre Bestellung',
    'order-num': ({ number }) => <>Bestellnr. {number}</>,
    'fulfilled-on': ({ date }) => <>Abgeschlossen am {date}</>,
    'email-notice': 'Sie erhalten eine E-Mail-Bestätigung Ihrer Bestellung.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Zurück zu {name}</>,
    'credit-applied': 'Guthaben angewendet'
  },
  'back': 'Zurück',
  'contact-info': 'Kontakt Informationen',
  'email-address': 'email',
  'payment-info': 'Rechnungsdaten',
  'shipping-address': 'Lieferanschrift',
  'legal': {
    'accept': 'Ich akzeptiere die',
    'privacy-policy': 'Datenschutzerklärung',
    'terms-of-service': 'Service-Bedingungen'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' und ',
      'lastWordConnector': ' und '
    }
  },
  'powered-by': ({ recurly }) => <>Rechnungsstellung bereitgestellt durch {recurly}</>,
  'errors': {},
  'subscribe': 'Abonnieren'
};
