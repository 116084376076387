import { useCheckoutSession } from '@/hooks/use-checkout-session';
import React, { useContext } from 'react';
import { CartContext } from '@/contexts/cart-context';
import { useLocale } from '@/hooks/use-locale';
import { SubtotalLine } from './subtotal-line';
import { CartInternalContext } from '@/contexts/cart-internal-context';

export function DiscountLine () {
  const [_, updateCheckoutSession] = useCheckoutSession();
  const { setCanApplyPromoCode } = useContext(CartInternalContext);
  const { price, pricing } = useContext(CartContext);
  const { t } = useLocale();

  const code = pricing?.items?.coupon?.code || t('cart.subtotal-line.gift-card');
  const amount = Number(price.now.discount) > 0 ? price.now.discount : price.now.giftCard;

  return (
    <SubtotalLine
      className="my-4"
      price={{ currency: price?.currency, amount }}
      priceClassName="text-green-600"
      subtractive
    >
      {code && (
        <div className="bg-green-200 text-green-600 font-medium rounded-md pl-4 pr-10 py-1 relative justify-self-start">
          {code}
          <button
            className="
              bg-green-200
              text-green-600
              font-bold
              px-3
              rounded-r-md
              py-1
              absolute
              right-0
              top-0
              hover:bg-green-300
              hover:text-green-800
            "
            onClick={event => {
              event.preventDefault();
              updateCheckoutSession({ cart: { coupons: [], giftCards: [] } });
              setCanApplyPromoCode(true);
            }}
          >
            ×
          </button>
        </div>
      )}
    </SubtotalLine>
  );
}
