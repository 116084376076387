import { CheckoutAddress } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME, nameFor } from '@/utils/address-utils';
import React from 'react';

export function ReflectiveAddress ({
  namePrefix = '',
  address
}: {
  namePrefix?: string;
  address?: CheckoutAddress;
}) {
  if (!address) return <></>;

  return [
    [ADDRESS_FIELD_NAME.FIRST_NAME, address.firstName],
    [ADDRESS_FIELD_NAME.LAST_NAME, address.lastName],
    [ADDRESS_FIELD_NAME.COMPANY, address.company],
    [ADDRESS_FIELD_NAME.ADDRESS_1, address.address1],
    [ADDRESS_FIELD_NAME.ADDRESS_2, address.address2],
    [ADDRESS_FIELD_NAME.CITY, address.city],
    [ADDRESS_FIELD_NAME.STATE, address.state],
    [ADDRESS_FIELD_NAME.COUNTRY, address.country],
    [ADDRESS_FIELD_NAME.POSTAL_CODE, address.postalCode],
    [ADDRESS_FIELD_NAME.PHONE, address.phone],
    [ADDRESS_FIELD_NAME.VAT_NUMBER, address.vatNumber]
  ].map(([name = '', from = ''], index) => {
    if (!from) return;
    const fieldName = nameFor(name, namePrefix);
    return (
      <input
        type="hidden"
        key={index}
        name={fieldName}
        data-recurly={fieldName}
        value={from}
      />
    );
  });
}
