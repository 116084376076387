import React, { StrictMode, useContext } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Elements,
  RecurlyProvider
} from '@recurly/react-recurly';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';

import { CheckoutRouterProvider } from './components/router';
import { EmptyState } from './components/empty-state';
import { EnvironmentContext, EnvironmentProvider } from './components/environment';
import { RecurlyProviderWithHostname } from './types/recurly';

import './index.css';

const CheckoutRecurlyProvider = RecurlyProvider as RecurlyProviderWithHostname;

export function App () {
  const [{ api }] = useContext(EnvironmentContext);

  return (
    <CheckoutRecurlyProvider hostname={window.location.hostname} api={api}>
      <Elements>
        <CheckoutRouterProvider />
      </Elements>
    </CheckoutRecurlyProvider>
  );
}

const root = document.getElementById('app');
if (!root) throw new Error('Failed to find the root element');

createRoot(root).render(
  <StrictMode>
    <ErrorBoundary fallback={<EmptyState />}>
      <EnvironmentProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </EnvironmentProvider>
    </ErrorBoundary>
  </StrictMode>
);
