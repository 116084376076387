import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { CartSubscription, CartSubscriptionAddOn } from '@/services/session';
import { SubscriptionPricingInstance } from '@recurly/recurly-js';
import React, { useContext } from 'react';
import { CartItem } from './cart-item';
import { NumericInput } from '@/components/form';
import { Price } from '@/components/price';
import { CartInternalContext } from '@/contexts/cart-internal-context';

export function CartItemSubscriptionAddOn ({
  cartAddOn: {
    code,
    quantity,
    quantityMin,
    quantityMax,
    quantityMutable
  },
  subscription,
  subscriptionPricing
}: {
  cartAddOn: CartSubscriptionAddOn;
  subscription: CartSubscription;
  subscriptionPricing: SubscriptionPricingInstance;
}) {
  const [{ cart }, updateCheckoutSession] = useCheckoutSession();
  const { currency, showCartEdits } = useContext(CartInternalContext);
  const { price } = subscriptionPricing;

  const plan = subscriptionPricing.items.plan;
  const addOn = plan.addons.find(a => a.code === code);

  const { name } = addOn;

  return (
    <CartItem
      period={plan.period}
      price={{ currency, amount: price.addons[code] }}
      priceClassName={plan.trial && 'line-through'}
      quantity={quantity}
      title={name}
      testGroupId='add-on'
      associated
    >
      <div className="text-checkout-subtle">
        {showCartEdits && quantityMutable && (
          <div>
            <NumericInput
              setValue={value => {
                const subscriptions = JSON.parse(JSON.stringify(cart.subscriptions));

                subscriptions
                  .find((s: CartSubscription) => s.ephemeralId === subscription.ephemeralId)
                  .addOns.find((a: CartSubscriptionAddOn) => a.code === code)
                  .quantity = value;

                updateCheckoutSession({ cart: { subscriptions } });
              }}
              min={quantityMin}
              max={quantityMax}
              value={quantity}
            />
            &nbsp;x <Price currency={currency} amount={price.base.addons[code]} />
          </div>
        ) || (
          (Number(quantity) > 1 || quantityMutable) && (
            <p>
              {quantity} x <Price currency={currency} amount={price.base.addons[code]} />
            </p>
          )
        )}
      </div>
    </CartItem>
  );
}
