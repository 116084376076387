import React, { PropsWithChildren, useEffect, useState } from 'react';

import { useLocale } from '@/hooks/use-locale';
import { Field, TextInput } from '../form';
import { PostalCodeAddress } from './postal-code-address';
import { CheckoutAddressProps } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME, autoCompleteValueFor, nameFor } from '@/utils/address-utils';

export function StreetAddress ({
  autoCompletePrefix,
  children,
  namePrefix = '',
  onChange = () => {},
  required = [],
  value
}: CheckoutAddressProps & PropsWithChildren) {
  const { t } = useLocale();
  const [address1, setAddress1] = useState(value?.address1 || '');
  const [address2, setAddress2] = useState(value?.address2 || '');

  useEffect(() => setAddress1(value?.address1 || ''), [value?.address1]);
  useEffect(() => setAddress2(value?.address2 || ''), [value?.address2]);

  return (
    <>
      <Field
        label={t('address.address1')}
        name={nameFor(ADDRESS_FIELD_NAME.ADDRESS_1, namePrefix)}
        required={required.includes(ADDRESS_FIELD_NAME.ADDRESS_1)}
        className="col-span-2"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-line1', autoCompletePrefix)}
          onBlur={() => onChange({ address1 })}
          onChange={event => setAddress1(event.target.value)}
          value={address1}
        />
      </Field>
      <Field
        label={t('address.address2')}
        name={nameFor(ADDRESS_FIELD_NAME.ADDRESS_2, namePrefix)}
        required={required.includes(ADDRESS_FIELD_NAME.ADDRESS_2)}
        className="col-span-2"
      >
        <TextInput
          autoComplete={autoCompleteValueFor('address-line2', autoCompletePrefix)}
          onBlur={() => onChange({ address2 })}
          onChange={event => setAddress2(event.target.value)}
          value={address2}
        />
      </Field>
      {children}
      <PostalCodeAddress {...{ namePrefix, onChange, required, value }} />
    </>
  );
}
