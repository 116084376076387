import React, {
  ChangeEventHandler,
  createContext,
  FocusEventHandler,
  FormEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useContext,
  useId
} from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronImage } from './images/chevron';
import { currentBrandContrastRgb } from './theme';
import { FormError } from '@/services/purchase';
import { LoadingImage } from './images/loading';

const inputClassName = (error?: FieldError, className?: string) => twMerge(
  'w-full',
  'mt-2',
  'p-2',
  'ring-1',
  'appearance-none',
  'rounded-md',
  'shadow-sm',
  'bg-transparent',
  'text-sm',
  'focus:ring-2',
  'focus:ring-blue-500',
  'focus:outline-none',
  error && 'ring-red-400' || 'ring-gray-400',
  className
);

export const FormContext = createContext<{
  error?: FormError;
  handleSubmit(_e?: FormEvent): void;
    }>({
      handleSubmit: () => {}
    });

export class FieldError {
  constructor (
    public name: string,
    public messages: string[]
  ) {
    this.name = name;
    this.messages = messages;
  }

  public get fullMessages (): string {
    return this.messages.join(', ');
  }
}

export const FieldGroup = ({ title, children }: { title: string; } & PropsWithChildren) => (
  <>
    <h2 className="text-xl mt-5 font-medium first:mt-0 col-span-2">
      {title}
    </h2>
    {children}
  </>
);

export const FieldContext = createContext<{
  description?: string;
  error?: FieldError;
  id: string;
  name: string;
}>({ id: '', name: '' });

export function Field ({
  children,
  className,
  description,
  error,
  label,
  name,
  required = false
}: {
  className?: string;
  description?: string;
  error?: FieldError;
  label?: string;
  name: string;
  required?: boolean;
} & PropsWithChildren) {
  const id = useId();
  const { error: formError } = useContext(FormContext);
  const fieldError = error || formError?.getFieldError(name);

  return (
    <div className={className || ''}>
      <label htmlFor={id} className={twMerge('text-gray-500', fieldError && 'text-red-400')}>
        {label}
        {required && (
          <span className="text-red-500">&nbsp;*</span>
        )}
      </label>
      <FieldContext.Provider value={{ description, error: fieldError, id, name }}>
        {children}
      </FieldContext.Provider>
      {fieldError && (
        <div id={`${id}-error`} className="text-red-400">{fieldError.fullMessages}</div>
      )}
      {description && (
        <div id={`${id}-description`}>{description}</div>
      )}
    </div>
  );
}

export const TextInput = forwardRef(function TextInput ({
  autoComplete = 'on',
  className = '',
  inputMode,
  onBlur,
  onChange,
  onKeyDown,
  onKeyUp,
  stealth = false,
  style = {},
  type = 'text',
  value
}: {
  autoComplete?: string;
  className?: string;
  inputMode?: string;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler;
  onKeyUp?: KeyboardEventHandler;
  stealth?: boolean;
  style?: object;
  type?: HTMLInputTypeAttribute;
  value?: string;
}, ref) {
  const {
    description,
    error,
    id,
    name
  } = useContext(FieldContext);

  const rest = { id, autoComplete, inputMode, onBlur, onChange, onKeyDown, onKeyUp, ref, style, type, value };

  if (name) {
    rest['data-recurly'] = name;
  }

  if (type === 'number') {
    rest.min = 1;
  }

  const classes = stealth
    ? twMerge('appearance-none bg-transparent', className)
    : inputClassName(error, className);

  return (
    <input
      className={classes}
      data-testid ={name}
      aria-describedby={`
        ${description && `${id}-description` || ''}
        ${error && `${id}-error` || ''}
      `}
      aria-invalid={Boolean(error)}
      { ...rest }
    />
  );
});

const NumericInputBumper = ({
  children,
  className = '',
  disabled = false,
  onClick
}: {
  className: string;
  disabled?: boolean;
  onClick: () => void;
} & PropsWithChildren) => (
  <button
    className={twMerge(
      'flex-none px-2 m-2 rounded-md active:bg-gray-200 text-lg disabled:opacity-25',
      className
    )}
    onClick={event => {
      event.preventDefault();
      onClick();
    }}
    {...{ disabled }}
  >
    {children}
  </button>
);

export function NumericInput ({
  autoComplete,
  className = '',
  max,
  min = '0',
  setValue,
  value = '1'
}: {
  autoComplete?: string;
  className?: string;
  max?: string;
  min?: string;
  setValue(_value: string): void;
  value?: string;
}) {
  const numericValue = Number(value);
  const numericMax = Number(max);
  const numericMin = Number(min);

  return (
    <div
      className={inputClassName(
        undefined,
        `inline-flex mr-1 p-0 w-auto ${className}`
      )}
    >
      <NumericInputBumper
        onClick={() => setValue(String(numericValue - 1))}
        className="mr-1"
        disabled={numericValue <= numericMin}
      >-</NumericInputBumper>
      <TextInput
        className="w-4 py-2"
        stealth={true}
        inputMode="numeric"
        style={{ width: `${value.length}ch` }}
        onChange={event => {
          const newValue = Number(event.target.value);

          if (isNaN(newValue) || newValue < numericMin || (numericMax && newValue > numericMax)) {
            event.preventDefault();
            return false;
          }

          setValue(event.target.value);
        }}
        {...{ autoComplete, value }}
      />
      <NumericInputBumper
        onClick={() => setValue(String(numericValue + 1))}
        className="ml-1"
        disabled={!!(numericMax && numericValue >= numericMax)}
      >+</NumericInputBumper>
    </div>
  );
}

export function SelectInput ({
  autoComplete = 'off',
  className,
  onChange,
  options,
  value
}: {
  autoComplete?: string;
  className?: string;
  onChange?: ChangeEventHandler;
  options: {
    value: string;
    name?: string;
  }[]
  value?: string;
}) {
  const {
    description,
    error,
    id,
    name
  } = useContext(FieldContext);

  return (
    <div className="relative">
      <select
        data-recurly={name}
        data-testid ={name}
        className={className || inputClassName(error)}
        autoComplete={autoComplete}
        aria-describedby={`
          ${description && `${id}-description` || ''}
          ${error && `${id}-error` || ''}
        `}
        aria-invalid={Boolean(error)}
        {...{ id, onChange, value }}
      >
        {options.map(({ value, name }, i) => (
          <option key={i} value={value}>{name || value}</option>)
        )}
      </select>
      <ChevronImage className="absolute top-1/2 -translate-y-1 right-4 h-4 pointer-events-none" />
    </div>
  );
}

export function CheckboxInput ({
  checked,
  children,
  onChange
}: PropsWithChildren & {
  checked: boolean;
  onChange?: ChangeEventHandler;
}) {
  const {
    description,
    error,
    id
  } = useContext(FieldContext);

  return (
    <div className={twMerge('flex items-center', error && 'ring-red-400 ring-1 rounded-md p-2')}>
      <input
        type="checkbox"
        aria-describedby={`
          ${description && `${id}-description` || ''}
          ${children && `${id}-text` || ''}
          ${error && `${id}-error` || ''}
        `}
        {...{ id, onChange, checked }}
      />
      <label htmlFor={id} id={`${id}-text`} className="pl-2">
        {children}
      </label>
    </div>
  );
}

export function Button ({
  className,
  children,
  disabled = false,
  onClick,
  processing = false,
  type = 'button',
  variant = 'primary',
}: {
  className?: string;
  disabled: boolean;
  onClick?: MouseEventHandler;
  processing?: boolean;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary';
} & PropsWithChildren) {
  let colors = 'bg-checkout-brand text-checkout-brand-contrast';

  if (variant === 'secondary') {
    colors = 'bg-checkout-accent';
  }

  return (
    <button
      className={twMerge(
        'py-2 px-4',
        'min-h-10',
        'rounded',
        'font-bold',
        'border-transparent',
        'disabled:opacity-70',
        colors,
        !processing && 'hover:brightness-90',
        className
      )}
      style={{
        transition: 'max-width 0.1s'
      }}
      disabled={processing || disabled}
      {...{ type, onClick }}
    >
      {
        processing
          ? (
            <div className="animate-pulse animate-spin h-8 w-8 mx-auto relative bottom-1">
              <LoadingImage color={currentBrandContrastRgb()} />
            </div>
          )
          : children
      }
    </button>
  );
}
