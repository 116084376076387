import merge from 'deepmerge';
import { nanoid } from 'nanoid';
import { useContext } from 'react';
import { CheckoutSessionContext, CheckoutSessionContextParams } from '@/components/session';

const overwrite = (_, src: object) => src;

export function useCheckoutSession (): CheckoutSessionContextParams {
  const [checkoutSession, setCheckoutSession] = useContext(CheckoutSessionContext);

  if (!checkoutSession) {
    throw new Error('Could not load a session');
  }

  // Decorate entries with ephemeral ids
  [
    ...(checkoutSession?.cart?.subscriptions || []),
    ...(checkoutSession?.cart?.lineItems || []),
  ].forEach(entry => entry.ephemeralId = entry.ephemeralId || `t-${nanoid()}`);

  return [
    checkoutSession,
    (params = {}) => setCheckoutSession(merge(checkoutSession, params, { arrayMerge: overwrite }))
  ];
}
