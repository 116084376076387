import { Dispatch, useState } from 'react';

export function useLocalStorage<Type> (key: string, defaultValue: Type): [Type, Dispatch<Type>] {
  const [value, setValue] = useState(() => (getStoredValue(key, localStorage) || defaultValue));

  return [value, (value: Type) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  }];
}

export function useSessionStorage<Type> (key: string, defaultValue: Type): [Type, Dispatch<Type>] {
  const [value, setValue] = useState(() => (getStoredValue(key, sessionStorage) || defaultValue));

  return [value, (value: Type) => {
    sessionStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  }];
}

function getStoredValue (key: string, store: Storage) {
  const saved = store.getItem(key);
  if (!saved) return;
  return JSON.parse(saved);
}
