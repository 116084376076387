import React, { useContext, useEffect } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { countryOptionsFor } from '@/services/locale';
import { PaymentMethodOption } from './option';
import { Field, SelectInput, TextInput } from '../../form';
import { PaymentMethodContext } from '../payment-methods';
import { useLocale } from '@/hooks/use-locale';

export function SepaPaymentMethodOption () {
  const { t, locale } = useLocale();
  const recurly = useRecurly();
  const {
    paymentMethod,
    paymentMethodForType,
    setBillingInfoTokenCreator
  } = useContext(PaymentMethodContext);
  const sepaPaymentMethod = paymentMethodForType('sepa');

  useEffect(() => {
    if (paymentMethod?.type !== sepaPaymentMethod?.type) return;
    setBillingInfoTokenCreator(() => (form, done) => recurly.bankAccount.token(form, done));
  }, [paymentMethod]);

  return (
    <PaymentMethodOption title={t('payment-method.sepa.name')} type="sepa">
      <Field
        label={t('payment-method.ach.name-on-account')}
        className="col-span-2"
        name="name_on_account"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.sepa.iban')}
        className="col-span-2"
        name="iban"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('address.country')}
        className="col-span-2"
        name="country"
        required
      >
        <SelectInput options={countryOptionsFor(locale, 'EU')} />
      </Field>
    </PaymentMethodOption>
  );
}
