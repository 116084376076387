import React from 'react';
export default {
  'cart': {
    'change-currency': 'Para birimini değiştir',
    'and-then': 'Daha sonrasında',
    'plus-tax': ' + vergi',
    'for': 'Kimin için',
    'edit': {
      'label': 'Düzenle',
      'submit': 'Uygula'
    },
    'empty': 'Siparişinize henüz bir şey eklemediniz.',
    'free': 'ücretsiz',
    'promo-code': {
      'add': 'Hediye kartı veya indirim kodu ekle',
      'label': 'Hediye kartı veya indirim kodu',
      'submit': 'Uygula'
    },
    'subtotal-line': {
      'tax-label': 'Tahmini vergi ücreti',
      'subtotal': 'Ara toplam',
      'tax': 'Vergi',
      'gift-card': 'hediye kartı'
    },
    'starting-price': 'Başlangıç fiyatı',
    'title': 'Sipariş Özeti',
    'setup-fee': 'kurulum ücreti',
    'free-trial': 'ücretsiz deneme',
    'total-line': {
      'label': 'Toplam'
    },
    'order-total-line': {
      'label': 'Toplam sipariş'
    }
  },
  'address': {
    'country': 'Ülke',
    'address2': 'Apartman, kapı numarası vs.',
    'tax-identifier': 'Vergi tanımlayıcı',
    'tax-identifier-type': 'Vergi tanımlayıcı türü',
    'first-name': 'İsim',
    'last-name': 'Soyadı',
    'company-name': 'Firma Adı',
    'address1': 'Sokak Adresi',
    'city': 'Şehir',
    'state': 'Il',
    'postal-code': 'Posta Kodu',
    'phone-number': 'Telefon numarası',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Kart bilgileri',
      'expiry': 'AA/YY',
      'name': 'Kredi Kartı',
      'number': 'Kredi Kartı Numarası',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Banka Hesabı',
      'routing-number': 'Bankanizin Routing Numarasi',
      'account-number': 'Hesap Numarası',
      'account-number-confirmation': 'Hesap Numaranizi Onaylayin',
      'account-type': {
        'label': 'Hesap Tipi',
        'checking': 'Vadesiz Mevduat ',
        'savings': 'Vadeli Mevduat'
      },
      'name-on-account': 'Hesap Sahibinin Ismi'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Fatura adresi',
  'billing-address-same-as-shipping': 'Teslimat adresiyle aynı',
  'submit': 'Şimdi öde',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} seçildi</>,
  'express-checkout': 'Ekspres Ödeme',
  'change-payment-method': 'Ödeme yöntemini değiştir',
  'day': 'gün',
  'days': 'gün',
  'week': 'hafta',
  'weeks': 'hafta',
  'month': 'ay',
  'months': 'ay',
  'year': 'yıl',
  'years': 'yıl',
  'confirmation': {
    'title': 'Siparişiniz için teşekkür ederiz',
    'order-num': ({ number }) => <>Sipariş #{number}</>,
    'fulfilled-on': ({ date }) => <>Tamamlandı {date}</>,
    'email-notice': 'Siparişiniz için bir e-posta doğrulaması alacaksınız.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>{name} dönün </>,
    'credit-applied': 'Kredi Uygulandı'
  },
  'back': 'Geri',
  'contact-info': 'İletişim Bilgileri',
  'email-address': 'E-posta',
  'payment-info': 'Ödeme Bilgileri',
  'shipping-address': 'teslimat adresi',
  'legal': {
    'accept': 'Kabul ediyorum',
    'privacy-policy': 'Gizlilik Politikası',
    'terms-of-service': 'Hizmet Koşulları'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' ve ',
      'lastWordConnector': ' ve '
    }
  },
  'powered-by': ({ recurly }) => <>Abonelik faturalandırması {recurly} tarafından yapılmaktadır</>,
  'errors': {},
  'subscribe': 'Abone olun'
};
