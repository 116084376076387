import React from 'react';

export function VenmoLogoImage ({
  className = ''
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="343" height="48" viewBox="0 0 343 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ className }}>
      <path d="M0 4C0 1.79086 1.79086 0 4 0H339C341.209 0 343 1.79086 343 4V44C343 46.2091 341.209 48 339 48H4.00001C1.79087 48 0 46.2091 0 44V4Z" fill="#008CFF"/>
      <g clipPath="url(#clip0_710_14004)">
        <path d="M145.321 16.75C145.847 17.5902 146.085 18.4556 146.085 19.5487C146.085 23.0353 143.004 27.5646 140.503 30.7451H134.791L132.5 17.5134L137.501 17.0547L138.713 26.4699C139.844 24.689 141.241 21.8903 141.241 19.9822C141.241 18.9377 141.056 18.2263 140.766 17.6406L145.321 16.75Z" fill="white"/>
        <path d="M151.803 22.577C152.723 22.577 155.041 22.1703 155.041 20.8982C155.041 20.2874 154.593 19.9827 154.066 19.9827C153.145 19.9827 151.935 21.0505 151.803 22.577ZM151.698 25.0964C151.698 26.6496 152.592 27.259 153.777 27.259C155.068 27.259 156.304 26.9543 157.91 26.1657L157.305 30.1345C156.173 30.6687 154.41 31.0251 152.697 31.0251C148.354 31.0251 146.8 28.4809 146.8 25.3003C146.8 21.178 149.328 16.8008 154.541 16.8008C157.411 16.8008 159.016 18.3539 159.016 20.5166C159.016 24.0031 154.384 25.0711 151.698 25.0964Z" fill="white"/>
        <path d="M173.449 19.8044C173.449 20.3132 173.37 21.0513 173.29 21.5336L171.789 30.6944H166.919L168.288 22.2969C168.314 22.0691 168.394 21.6106 168.394 21.3561C168.394 20.7453 167.999 20.5929 167.524 20.5929C166.893 20.5929 166.261 20.8725 165.839 21.0766L164.287 30.6945H159.389L161.627 16.9792H165.865L165.919 18.0739C166.919 17.4379 168.236 16.75 170.104 16.75C172.579 16.7497 173.449 17.9715 173.449 19.8044Z" fill="white"/>
        <path d="M187.908 18.3022C189.302 17.3363 190.62 16.8008 192.435 16.8008C194.936 16.8008 195.806 18.0226 195.806 19.8555C195.806 20.3643 195.726 21.1024 195.647 21.5847L194.148 30.7455H189.276L190.671 22.1704C190.697 21.9412 190.751 21.6616 190.751 21.4842C190.751 20.7965 190.356 20.6439 189.881 20.6439C189.276 20.6439 188.671 20.8983 188.222 21.1277L186.67 30.7456H181.8L183.195 22.1705C183.22 21.9413 183.273 21.6617 183.273 21.4844C183.273 20.7966 182.877 20.6441 182.404 20.6441C181.772 20.6441 181.141 20.9237 180.72 21.1278L179.166 30.7458H174.27L176.507 17.0304H180.694L180.826 18.1754C181.8 17.4892 183.116 16.8013 184.879 16.8013C186.407 16.8008 187.406 17.437 187.908 18.3022Z" fill="white"/>
        <path d="M205.497 22.2976C205.497 21.178 205.207 20.4146 204.339 20.4146C202.417 20.4146 202.022 23.6968 202.022 25.3758C202.022 26.6496 202.391 27.4379 203.259 27.4379C205.075 27.4379 205.497 23.9765 205.497 22.2976ZM197.073 25.1731C197.073 20.8479 199.442 16.8008 204.892 16.8008C208.999 16.8008 210.5 19.1424 210.5 22.3744C210.5 26.6495 208.157 31.0767 202.575 31.0767C198.442 31.0767 197.073 28.4555 197.073 25.1731Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_710_14004">
          <rect width="78.0001" height="14.5" fill="white" transform="translate(132.5 16.75)"/>
        </clipPath>
      </defs>
    </svg>
  );
}
