import React, { useEffect } from 'react';

/**
 * TODO
 *
 * If we want to translate the EmptyState messages, then useLocale will just
 * need to have a method of recovering from having no CheckoutSession
 */
export function EmptyState ({ message = 'Something went wrong' }) {
  return (
    <main className="flex p-24">
      <header className="mx-auto text-2xl p-5 text-gray-400 bg-checkout-accent rounded-lg">
        {message}
      </header>
    </main>
  );
}

export const NotFound = () => <EmptyState message="Not found" />;
export const Loading = () => <EmptyState message="Loading..." />;
export const Redirecting = () => <EmptyState message="Redirecting..." />;
export const SessionExpired = () => <EmptyState message="Your session has expired. Please refresh to continue." />;
export const SessionHasNoPurchase = () => {
  useEffect(() => {
    // Redirect back if no purchase has been made
    history.back();
  }, []);

  return <EmptyState message="Redirecting..." />;
};
