import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Invoice, LineItem, TransactionPaymentMethod } from '@/services/purchase';
import { Theme } from './theme';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';
import {
  AchAcceptanceMark,
  AmazonPayAcceptanceMark,
  AmexAcceptanceMark,
  ApplePayAcceptanceMark,
  BankAccountAcceptanceMark,
  CreditCardAcceptanceMark,
  DiscoverAcceptanceMark,
  MasterCardAcceptanceMark,
  PayPalAcceptanceMark,
  SepaAcceptanceMark,
  VisaAcceptanceMark
} from './images/acceptance-marks';
import { Redirecting } from './empty-state';
import { CheckoutAddress } from './checkout/address-form';
import { Price } from './price';

const longDateString = (utc: string) => {
  const date = new Date(utc);
  return `${date.toDateString()}, ${date.toLocaleTimeString()}`;
};

export function Confirmation () {
  const [{
    finishUrl,
    logoUrl,
    purchases,
    site: {
      name: siteName
    }
  }] = useCheckoutSession();
  const { t } = useLocale();

  const fulfilledDate = longDateString(purchases[0].invoices[0].createdAt);

  return purchases
    ? (
      <main className="font-sans p-8">
        <Theme />
        <header className="max-w-xl mx-auto mt-6 mb-10 lg:mt-8">
          {logoUrl && (
            <img className="w-1/6" src={logoUrl} alt={siteName} />
          )}
        </header>
        <section className="max-w-xl mx-auto">
          <h1 className="text-4xl mb-2 font-medium">
            {t('confirmation.title')}
          </h1>
          <p className="text-checkout-subtle">
            {t('confirmation.order-num', purchases[0].invoices[0])}
          </p>
          <p className="text-checkout-subtle">
            {t('confirmation.fulfilled-on', { date: fulfilledDate })}
          </p>

          {purchases.map(p => p.invoices.map((invoice, i) => <PaymentSummary key={i} invoice={invoice} />))}

          {finishUrl && (
            <a href={finishUrl}>{t('confirmation.return-to', { name: siteName })}</a>
          )}
        </section>
      </main>
    )
    : (
      <Redirecting />
    );
}

function PaymentSummary ({ invoice }: { invoice: Invoice }) {
  const { t } = useLocale();

  if (invoice.type === 'credit') {
    return;
  }

  const recurringCosts = Object.entries(
    invoice.lineItems.reduce((acc, lineItem) => {
      if (!lineItem.recurring || !lineItem.plan) return acc;

      const interval = t.intervalFor(lineItem.plan.period);
      const { code, symbol } = lineItem.amount.currency;

      acc[interval] = [
        { code, symbol },
        (acc[interval] && acc[interval][1] || 0) + lineItem.amount.amount
      ];

      return acc;
    }, {})
  ).map(([interval, [{ code, symbol }, amount]], index) => (
    <span key={index}>
      {index > 0 && ', '}
      <Price currency={{ code, symbol }} amount={amount} />
      /{interval}
    </span>
  ));

  return (
    <section className="max-w-xl mx-auto mt-10 grid grid-cols-2">
      {invoice.transactions?.length > 0 && (
        <>
          <h2 className="text-lg font-medium mb-4 col-span-2">
            {t('confirmation.payment')}
          </h2>
          <PaymentMethod {...invoice.transactions[0].paymentMethod} />
        </>
      )}

      {invoice.billingAddress && (
        <AddressSummary title={t('billing-address')} {...invoice.billingAddress} />
      )}

      {invoice.shippingAddress && (
        <AddressSummary title={t('shipping-address')} {...invoice.shippingAddress} />
      )}

      {invoice.account.emailAddressPresent && (
        <div className="col-span-2 mb-8 pb-8 border-b-2">
          {invoice.account.emailAddress && (
            <div className="mb-6 font-medium">{invoice.account.emailAddress}</div>
          )}
          <p>{t('confirmation.email-notice')}</p>
        </div>
      )}

      <div className="border-b-2 col-span-2 pb-6 mb-8">
        <LineItems lineItems={invoice.lineItems} />
      </div>

      {invoice.subtotal.amount !== invoice.paid.amount && (
        <div className="border-b-2 col-span-2 pb-6 mb-6 grid grid-cols-2 gap-3">
          <div>{t('cart.subtotal-line.subtotal')}</div>
          <div className="justify-self-end">
            <Price {...invoice.subtotal} />
          </div>

          {Number(invoice.discount.amount) > 0 && (
            <>
              <div>
                <span className="bg-green-100 text-green-700 font-semibold text-sm rounded-md p-2">
                  {invoice.couponRedemptions.map(couponRedemption => couponRedemption.coupon.code).join(', ')}
                </span>
              </div>
              <div className="justify-self-end text-green-700">
                -
                <Price {...invoice.discount} />
              </div>
            </>
          )}

          {Number(invoice.tax.amount) > 0 && (
            <>
              <div>{t('cart.subtotal-line.tax')}</div>
              <div className="justify-self-end">
                <Price {...invoice.tax} />
              </div>
            </>
          )}

          {Number(invoice.credit.amount) > 0 && (
            <>
              <div>
                <span className="bg-green-100 text-green-700 font-semibold text-sm rounded-md p-2">
                  {t('confirmation.credit-applied')}
                </span>
              </div>
              <div className="justify-self-end text-green-700">
                -
                <Price {...invoice.credit} />
              </div>
            </>
          )}
        </div>
      )}

      <div className="col-span-2 mb-6 grid grid-cols-2 gap-3">
        <div className="text-2xl font-medium">{t('cart.order-total-line.label')}</div>
        <div className="text-2xl font-medium justify-self-end">
          <Price {...invoice.paid} />
        </div>

        {recurringCosts.length > 0 && (
          <div className="text-checkout-subtle">
            {t('cart.and-then')} {recurringCosts}
          </div>
        )}
      </div>
    </section>
  );
}

function AddressSummary ({
  title,
  firstName,
  lastName,
  address1,
  address2,
  city,
  state,
  country,
  postalCode
}: CheckoutAddress & {
  title: string;
}) {
  const present = [firstName, lastName, address1, address2, city, state, postalCode, country].some(Boolean);

  if (!present) {
    return <></>;
  }

  return (
    <div className="mb-6">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      <div>{firstName} {lastName}</div>
      <div>{address1}</div>
      <div>{address2}</div>
      <div>{[city, `${state || ''} ${postalCode || ''}`].filter(Boolean).join(', ')}</div>
      <div>{country}</div>
    </div>
  );
}

function PaymentMethod ({ type, cardBrand, cardBrandName, lastFour }: TransactionPaymentMethod) {
  const { t } = useLocale();

  let info = <></>;

  if (type === 'credit_card') {
    let mark = <CreditCardAcceptanceMark />;

    if (cardBrand === 'visa') {
      mark = <VisaAcceptanceMark />;
    } else if (cardBrand === 'discover') {
      mark = <DiscoverAcceptanceMark />;
    } else if (cardBrand === 'american_express') {
      mark = <AmexAcceptanceMark />;
    } else if (cardBrand === 'master') {
      mark = <MasterCardAcceptanceMark />;
    }

    info = <>{mark} {cardBrandName} •••• {lastFour}</>;
  } else if (type === 'paypal') {
    info = <><PayPalAcceptanceMark /> {t('payment-method.paypal.name')}</>;
  } else if (type === 'apple_pay') {
    info = <><ApplePayAcceptanceMark /> {t('payment-method.apple-pay.name')}</>;
  } else if (type === 'amazon') {
    info = <><AmazonPayAcceptanceMark /> {t('payment-method.amazon.name')}</>;
  } else if (type === 'ach') {
    info = <><AchAcceptanceMark /> {t('payment-method.ach.name')}</>;
  } else if (type === 'bacs') {
    info = <><BankAccountAcceptanceMark /> {t('payment-method.bacs.name')}</>;
  } else if (type === 'becs') {
    info = <><BankAccountAcceptanceMark /> {t('payment-method.becs.name')}</>;
  } else if (type === 'sepa') {
    info = <><SepaAcceptanceMark /> {t('payment-method.sepa.name')}</>;
  }

  return (
    <div className="bg-checkout-accent p-4 mb-6 rounded-lg flex gap-3 col-span-2">
      {info}
    </div>
  );
}

const associatedLineItemsForLineItem = (subscriptionId: string | undefined, lineItems: LineItem[]): LineItem[] => {
  return lineItems.filter(
    l => l.subscriptionId === subscriptionId
    && !!(l.addOnId || l.origin === 'setup_fee')
  );
};

function LineItems ({ lineItems }: { lineItems: LineItem[]; }) {
  const subscriptionLineItems = lineItems.filter(l => !!l.subscriptionId && !(l.addOnId || l.origin === 'setup_fee'));
  const oneTimeLineItems = lineItems.filter(l => !l.subscriptionId);

  return (
    <>
      {subscriptionLineItems.map((subLineItem, index) => (
        <div className="mb-4" key={index}>
          <LineItemSummary priceClassName="font-semibold" {...subLineItem} />

          {associatedLineItemsForLineItem(subLineItem.subscriptionId, lineItems).map((assocLineItem, index) => (
            <LineItemSummary
              key={index}
              descriptionClassName="font-normal"
              className="border-l-2 pl-4"
              {...assocLineItem}
            />
          ))}
        </div>
      ))}

      {oneTimeLineItems.map((oneTimeLineItem, index) => (
        <LineItemSummary key={index} className="mb-4" {...oneTimeLineItem} />
      ))}
    </>
  );
}

function LineItemSummary ({
  description,
  descriptionClassName,
  plan,
  quantity,
  recurring,
  subtotal,
  unitAmount,
  className,
  priceClassName
}: LineItem & {
  className?: string;
  descriptionClassName?: string;
  priceClassName?: string;
}) {
  const { t } = useLocale();

  return (
    <div className={twMerge('grid grid-cols-2 pb-2 last:pb-0', className)}>
      <div>
        <span className={twMerge('font-semibold', descriptionClassName)}>
          {description}
        </span>
        {Number(quantity) > 1 && (
          <div className="text-sm text-checkout-subtle">
            {quantity} x <Price {...unitAmount} />
          </div>
        )}
      </div>
      <div className={twMerge('justify-self-end', priceClassName)}>
        <Price {...subtotal} />
        {recurring && plan && (
          `/${t.intervalFor(plan.period)}`
        )}
      </div>
    </div>
  );
}
