import React from 'react';
export default {
  'cart': {
    'change-currency': 'Изменить валюту',
    'and-then': 'А затем',
    'plus-tax': ' + налог',
    'for': 'Для',
    'edit': {
      'label': 'Редактировать',
      'submit': 'Применить'
    },
    'empty': 'Вы еще ничего не добавили в свой заказ.',
    'free': 'бесплатно',
    'promo-code': {
      'add': 'Добавить подарочную карту или промокод',
      'label': 'Подарочная карта или промокод',
      'submit': 'Применить'
    },
    'subtotal-line': {
      'tax-label': 'Приблизительный налог',
      'subtotal': 'Подытог',
      'tax': 'Налог',
      'gift-card': 'подарочная карта'
    },
    'starting-price': 'Начальная цена',
    'title': 'Заказать Основная',
    'setup-fee': 'стоимость настройки',
    'free-trial': 'бесплатная пробная версия',
    'total-line': {
      'label': 'Всего'
    },
    'order-total-line': {
      'label': 'Общая сумма заказа'
    }
  },
  'address': {
    'country': 'Страна',
    'address2': 'Квартира, номер, и т.д.',
    'tax-identifier': 'Налоговый идентификатор',
    'tax-identifier-type': 'Тип налогового идентификатора',
    'first-name': 'Имя',
    'last-name': 'Фамилия',
    'company-name': 'название компании',
    'address1': 'Адрес улица',
    'city': 'Город',
    'state': 'Провинция',
    'postal-code': 'Почтовый индекс',
    'phone-number': 'Номер телефона',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Реквизиты карты',
      'expiry': 'ММ/ГГ',
      'name': 'кредитная карта',
      'number': 'Номер кредитной карты',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Банковский счет',
      'routing-number': 'Kод банка',
      'account-number': 'Номер Счета',
      'account-number-confirmation': 'Подтвердите номер счета',
      'account-type': {
        'label': 'Тип Счета',
        'checking': 'Tекущий банковский счет',
        'savings': 'Сберегательный счет'
      },
      'name-on-account': 'Имя Счета'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Платежный адрес',
  'billing-address-same-as-shipping': 'Тот же, что и адрес доставки',
  'submit': 'Оплатить сейчас',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} выбрано</>,
  'express-checkout': 'Экспресс-оформление заказа',
  'change-payment-method': 'Изменить способ оплаты',
  'day': 'день',
  'days': 'дни',
  'week': 'неделя',
  'weeks': 'недели',
  'month': 'месяц',
  'months': 'месяцы',
  'year': 'годы',
  'years': 'годы',
  'confirmation': {
    'title': 'Спасибо за ваш заказ',
    'order-num': ({ number }) => <>Заказ №{number}</>,
    'fulfilled-on': ({ date }) => <>Размещен {date}</>,
    'email-notice': 'Вы получите электронное письмо с подтверждением вашего заказа.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Вернуться к {name}</>,
    'credit-applied': 'кредит применен'
  },
  'back': 'Назад',
  'contact-info': 'Контактная информация',
  'email-address': 'Эл. адрес',
  'payment-info': 'Платежная информация',
  'shipping-address': 'Адрес доставки',
  'legal': {
    'accept': 'Я принимаю',
    'privacy-policy': 'Политика конфиденциальности ',
    'terms-of-service': 'Условия предоставления услуг'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' и ',
      'lastWordConnector': ' и '
    }
  },
  'powered-by': ({ recurly }) => <>Счета за подписку формируются при поддержке  {recurly}</>,
  'errors': {},
  'subscribe': 'Подписаться'
};
