import React, { useEffect, useState } from 'react';
import { useLocale } from '@/hooks/use-locale';
import { Field, SelectInput, TextInput } from '../form';
import { CheckoutAddress } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME } from '@/utils/address-utils';

export function TaxIdentifier ({
  address,
  onAddressChange = () => {},
}: {
  address: CheckoutAddress,
  onAddressChange: (_address: CheckoutAddress) => void
}) {
  const { t } = useLocale();
  const [taxIdentifier, setTaxIdentifier] = useState(address?.taxIdentifier || '');
  const [taxIdentifierType, setTaxIdentifierType] = useState(address?.taxIdentifierType || '');

  useEffect(() => setTaxIdentifier(address?.taxIdentifier || ''), [address?.taxIdentifier]);
  useEffect(() => setTaxIdentifierType(address?.taxIdentifierType || ''), [address?.taxIdentifierType]);

  return (
    <>
      <Field
        label={t('address.tax-identifier')}
        name={ADDRESS_FIELD_NAME.TAX_IDENTIFIER}
        className="col-span-2 xs:col-span-1"
      >
        <TextInput
          onBlur={() => onAddressChange({ taxIdentifier })}
          onChange={event => setTaxIdentifier(event.target.value)}
          value={taxIdentifier}
        />
      </Field>

      <Field
        label={t('address.tax-identifier-type')}
        name={ADDRESS_FIELD_NAME.TAX_IDENTIFIER_TYPE}
        className="col-span-2 xs:col-span-1"
      >
        <SelectInput
          onChange={event => {
            const value = event.target.value;
            setTaxIdentifierType(value);
            onAddressChange({ taxIdentifierType: value });
          }}
          options={[
            { name: 'CPF', value: 'cpf' },
            { name: 'CNPJ', value: 'cnpj' },
            { name: 'CUIT', value: 'cuit' }
          ]}
          value={taxIdentifierType}
        />
      </Field>
    </>
  );
}
