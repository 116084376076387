import React from 'react';
export default {
  'cart': {
    'change-currency': 'Zmień walutę',
    'and-then': 'A potem',
    'plus-tax': ' + podatek',
    'for': 'Za',
    'edit': {
      'label': 'Edytuj',
      'submit': 'Zastosuj'
    },
    'empty': 'Na razie nie dodano niczego do zamówienia.',
    'free': 'darmowe',
    'promo-code': {
      'add': 'Dodaj kartę upominkową lub kod promocyjny',
      'label': 'Karta upominkowa lub kod promocyjny',
      'submit': 'Zastosuj'
    },
    'subtotal-line': {
      'tax-label': 'Szacowany podatek',
      'subtotal': 'Podsuma',
      'tax': 'Podatek',
      'gift-card': 'Karta podarunkowa'
    },
    'starting-price': 'Cena wywoławcza',
    'title': 'podsumowanie zamowienia',
    'setup-fee': 'opłata instalacyjna',
    'free-trial': 'Bezpłatna wersja próbna',
    'total-line': {
      'label': 'Całość'
    },
    'order-total-line': {
      'label': 'Całość Zamówienia'
    }
  },
  'address': {
    'country': 'Kraj',
    'address2': 'Numer mieszkania itp.',
    'tax-identifier': 'Identyfikator podatkowy',
    'tax-identifier-type': 'Typ identyfikatora podatkowego',
    'first-name': 'Imię',
    'last-name': 'nazwisko',
    'company-name': 'Nazwa firmy',
    'address1': 'Adres ulicy',
    'city': 'Miasto',
    'state': 'stan',
    'postal-code': 'Kod pocztowy',
    'phone-number': 'numer telefonu',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Dane karty',
      'expiry': 'MM/RR',
      'name': 'Karta Kredytowa',
      'number': 'Numer karty',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'konto bankowe',
      'routing-number': 'Numer rozliczeniowy',
      'account-number': 'Numer konta',
      'account-number-confirmation': 'Potwierdź numer konta',
      'account-type': {
        'label': 'Typ konta',
        'checking': 'kontrola',
        'savings': 'oszczędności'
      },
      'name-on-account': 'Nazwa konta'
    },
    'amazon': {
      'name': 'Amazon Pay'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Kod sortowania'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'Kod BSB'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'ideał'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Adres rozliczeniowy',
  'billing-address-same-as-shipping': 'Taki sam jak adres wysyłki',
  'submit': 'Zapłać teraz',
  'payment-method-selected': ({ paymentMethod }) => <>Wybrano {paymentMethod}</>,
  'express-checkout': 'Ekspresowa kasa',
  'change-payment-method': 'Zmień metodę płatności',
  'day': 'dzień',
  'days': 'dni',
  'week': 'tydzień',
  'weeks': 'tygodnie/tygodni',
  'month': 'miesiąc',
  'months': 'miesiące/miesięcy',
  'year': 'rok',
  'years': 'lata/lat',
  'confirmation': {
    'title': 'Dziękujemy za złożenie zamówienia',
    'order-num': ({ number }) => <>Zamówienie nr {number}</>,
    'fulfilled-on': ({ date }) => <>Złożone dnia {date}</>,
    'email-notice': 'Otrzymasz mailowe potwierdzenie złożenia zamówienia.',
    'payment': 'Zapłata',
    'return-to': ({ name }) => <>Wróć do {name}</>,
    'credit-applied': 'Kredyt Zastosowany'
  },
  'back': 'Wróć',
  'contact-info': 'Informacje kontaktowe',
  'email-address': 'E-mail',
  'payment-info': 'Informacje rozliczeniowe',
  'shipping-address': 'adres wysyłki',
  'legal': {
    'accept': 'Akceptuję',
    'privacy-policy': 'Polityka prywatności',
    'terms-of-service': 'Warunki usługi'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' i ',
      'lastWordConnector': ' oraz '
    }
  },
  'powered-by': ({ recurly }) => <>Rozliczenia subskrypcji obsługiwane przez {recurly}</>,
  'errors': {},
  'subscribe': 'Subskrybuj'
};
