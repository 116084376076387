import React, { useContext, useState } from 'react';
import { CartContext } from '@/contexts/cart-context';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';
import { Field, SelectInput } from '@/components/form';

export function CurrencySelector () {
  const { priceLoading } = useContext(CartContext);
  const [{
    cart: {
      currency,
      currencies
    }
  }, updateCheckoutSession] = useCheckoutSession();
  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const { t } = useLocale();

  if (priceLoading) {
    return <div className="h-5"></div>;
  }

  return (
    <div className="mt-5">
      {
        showCurrencySelector
          ? (
            <Field name="change-currency" label={t('cart.change-currency')}>
              <div className="relative">
                <SelectInput
                  options={currencies.map(({ code }) => ({ value: code, name: code }))}
                  onChange={event => {
                    updateCheckoutSession({ cart: { currency: { code: event.target.value } } });
                    setShowCurrencySelector(false);
                  }}
                  value={currency?.code}
                />
              </div>
            </Field>
          )
          : (
            <a
              href="#"
              onClick={event => {
                event.preventDefault();
                setShowCurrencySelector(true);
              }}
            >
              {t('cart.change-currency')}
            </a>
          )
      }
    </div>
  );
}
