import React from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  useRouteError
} from 'react-router-dom';
import {
  SessionFromActionOrSessionIdParam,
  SessionFromConfigurationIdParam,
  SessionFromHostname,
  SessionFromSessionIdParam,
} from './session';
import {
  sessionIsActive,
  sessionHasPurchase
} from '@/services/session';
import { Checkout } from './checkout';
import { Confirmation } from './confirmation';
import { EmptyState, NotFound, SessionHasNoPurchase } from './empty-state';

function ErrorBoundary () {
  const error = useRouteError();
  console.error(error);
  return <EmptyState />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <SessionFromHostname test={sessionIsActive}>
        <Checkout />
      </SessionFromHostname>
    ),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/s/:checkoutSessionId',
    element: (
      <SessionFromSessionIdParam test={sessionIsActive}>
        <Checkout />
      </SessionFromSessionIdParam>
    ),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/c/:checkoutConfigurationId',
    element: (
      <SessionFromConfigurationIdParam test={sessionIsActive}>
        <Checkout />
      </SessionFromConfigurationIdParam>
    ),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/order/:checkoutSessionId',
    action: async ({ request }) => {
      const body = await request.json();

      if (!(body.checkoutSession)) {
        return redirect('/');
      }

      return body;
    },
    element: (
      <SessionFromActionOrSessionIdParam
        test={sessionHasPurchase}
        onTestFailure={() => <SessionHasNoPurchase />}
      >
        <Confirmation />
      </SessionFromActionOrSessionIdParam>
    )
  },
  {
    path: '*',
    element: <NotFound />,
    errorElement: <ErrorBoundary />
  }
]);

export const CheckoutRouterProvider = () => <RouterProvider router={router} />;
