import React from 'react';
export default {
  'cart': {
    'change-currency': 'Cambiar moneda',
    'and-then': 'Y después',
    'plus-tax': ' + impuestos',
    'for': 'Por',
    'edit': {
      'label': 'Editar',
      'submit': 'Aplicar'
    },
    'empty': 'Aún no ha añadido nada a su pedido.',
    'free': 'gratis',
    'promo-code': {
      'add': 'Agregar tarjeta de regalo o código de promoción',
      'label': 'Tarjeta de regalo o código de promoción',
      'submit': 'Aplicar'
    },
    'subtotal-line': {
      'tax-label': 'Impuestos estimados',
      'subtotal': 'Total Parcial',
      'tax': 'Impuesto',
      'gift-card': 'Tarjeta de regalo'
    },
    'starting-price': 'Precio inicial',
    'title': 'Resumen del pedido',
    'setup-fee': 'tarifa de apertura',
    'free-trial': 'Prueba gratis',
    'total-line': {
      'label': 'Total'
    },
    'order-total-line': {
      'label': 'Total del pedido'
    }
  },
  'address': {
    'country': 'País',
    'address2': 'Departamento, suite, etc.',
    'tax-identifier': 'Identificador fiscal',
    'tax-identifier-type': 'Tipo de identificador fiscal',
    'first-name': 'Nombre de pila',
    'last-name': 'Apellido',
    'company-name': 'Nombre de Empresa',
    'address1': 'Dirección',
    'city': 'Ciudad',
    'state': 'Estado',
    'postal-code': 'Código postal',
    'phone-number': 'Número de teléfono',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Datos de la tarjeta',
      'expiry': 'MM/AA',
      'name': 'Tarjeta De Crédito',
      'number': 'Número de tarjeta',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Cuenta Bancaria',
      'routing-number': 'Número de ruta',
      'account-number': 'Número De Cuenta',
      'account-number-confirmation': 'Confirmar Número de cuenta',
      'account-type': {
        'label': 'Tipo De Cuenta',
        'checking': 'Cuenta de cheques',
        'savings': 'Cuenta de ahorros'
      },
      'name-on-account': 'Nombre en la cuenta'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Dirección de facturación',
  'billing-address-same-as-shipping': 'La misma que la dirección de envío',
  'submit': 'Pagar ahora',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} seleccionado</>,
  'express-checkout': 'Pago exprés',
  'change-payment-method': 'Cambiar método de pago',
  'day': 'día',
  'days': 'días',
  'week': 'semana',
  'weeks': 'semanas',
  'month': 'mes',
  'months': 'meses',
  'year': 'año',
  'years': 'años',
  'confirmation': {
    'title': 'Gracias por su pedido',
    'order-num': ({ number }) => <>Pedido #{number}</>,
    'fulfilled-on': ({ date }) => <>Completado el {date}</>,
    'email-notice': 'Recibirá una confirmación por correo electrónico de su pedido.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Volver a {name}</>,
    'credit-applied': 'Crédito Aplicada'
  },
  'back': 'Regresar',
  'contact-info': 'Información de Contacto',
  'email-address': 'Correo electrónico',
  'payment-info': 'Datos de Facturación',
  'shipping-address': 'Dirección de envío',
  'legal': {
    'accept': 'Acepto ',
    'privacy-policy': 'Política de Privacidad',
    'terms-of-service': 'Condiciones del servicio'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' y ',
      'lastWordConnector': ', y '
    }
  },
  'powered-by': ({ recurly }) => <>Suscripción de facturación potenciado por {recurly}</>,
  'errors': {},
  'subscribe': 'Suscribirse'
};
