import React from 'react';
export default {
  'cart': {
    'change-currency': 'Skift valuta',
    'and-then': 'Og så',
    'plus-tax': ' + skat',
    'for': 'For',
    'edit': {
      'label': 'Rediger',
      'submit': 'Anvend'
    },
    'empty': 'Du har ikke tilføjet noget til din bestilling endnu.',
    'free': 'gratis',
    'promo-code': {
      'add': 'Tilføj gavekort eller rabatkode',
      'label': 'Gavekort eller rabatkode',
      'submit': 'Anvend'
    },
    'subtotal-line': {
      'tax-label': 'Estimeret skat',
      'subtotal': 'Subtotal',
      'tax': 'Moms',
      'gift-card': 'gavekort'
    },
    'starting-price': 'Startpris',
    'title': 'Ordre Resumé',
    'setup-fee': 'opsætning gebyr',
    'free-trial': 'gratis prøveversion',
    'total-line': {
      'label': 'I alt'
    },
    'order-total-line': {
      'label': 'Ordre total'
    }
  },
  'address': {
    'country': 'Land',
    'address2': 'Lejlighed, suite, etc.',
    'tax-identifier': 'Skatteidentifikator',
    'tax-identifier-type': 'Skatteidentifikatortype',
    'first-name': 'Fornavn',
    'last-name': 'Efternavn',
    'company-name': 'Virksomhedsnavn',
    'address1': 'Vej',
    'city': 'By',
    'state': 'Provins',
    'postal-code': 'Postnummer',
    'phone-number': 'Telefonnummer',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Kortdetaljer',
      'expiry': 'MM/ÅÅ',
      'name': 'Kreditkort',
      'number': 'Kortnummer',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Bankkonto',
      'routing-number': 'Routing Number',
      'account-number': 'Kontonummer',
      'account-number-confirmation': 'Bekræft kontonummer',
      'account-type': {
        'label': 'Kontotype',
        'checking': 'Checkkonto',
        'savings': 'Opsparingskonto'
      },
      'name-on-account': 'Navn på konto'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Faktureringsadresse',
  'billing-address-same-as-shipping': 'Samme som leveringsadresse',
  'submit': 'Betal nu',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} valgt</>,
  'express-checkout': 'Ekspresbetaling',
  'change-payment-method': 'Skift betalingsmetode',
  'day': 'dag',
  'days': 'dage',
  'week': 'uge',
  'weeks': 'uger',
  'month': 'måned',
  'months': 'måneder',
  'year': 'år',
  'years': 'år',
  'confirmation': {
    'title': 'Mange tak for din ordre',
    'order-num': ({ number }) => <>Ordre #{number}</>,
    'fulfilled-on': ({ date }) => <>Udført den {date}</>,
    'email-notice': 'Du vil modtage en bekræftelse til din e-mail på din ordre.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Retur til {name}</>,
    'credit-applied': 'Kredit tilladt'
  },
  'back': 'Tilbage',
  'contact-info': 'Kontaktinformation',
  'email-address': 'email',
  'payment-info': 'Faktureringsoplysninger',
  'shipping-address': 'Leveringsadresse',
  'legal': {
    'accept': 'Jeg accepterer',
    'privacy-policy': 'Privacy Policy',
    'terms-of-service': 'Servicevilkår'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' og ',
      'lastWordConnector': ' og '
    }
  },
  'powered-by': ({ recurly }) => <>Abonnement fakturering drevet af {recurly}</>,
  'errors': {},
  'subscribe': 'Abonner'
};
