import React from 'react';
import { countries } from 'countries-list';
import { euMember } from 'is-european';
import LocaleCurrency from 'locale-currency';
import memo from 'memoize-one';

import { Currency } from '@/components/price';
import {
  languageSupported,
  translations,
  translatedCountryNameFor
} from './locale/translations';
import { Period } from './purchase';

export type Translator = {
  (_key: string, _opts?: object): string;
  toSentence: (_items: any[]) => React.JSX.Element | string;
  intervalFor: (_period: Period) => string;
}

export const countryOptionsFor = memo(
  ({ language }: Intl.Locale, administrativeMembership?: 'EU') => (
    Object.entries(countries)
      .filter(([countryCode, _details]) => !administrativeMembership || euMember(countryCode))
      .map(([countryCode, _details]) => ({
        value: countryCode,
        name: translatedCountryNameFor(countryCode, language)
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  )
);

export const localeForTag = (tag: string) => new Intl.Locale(tag);

export const browserLocale = () => localeForTag(Intl.NumberFormat().resolvedOptions().locale);
export const browserLanguageLocales = () => navigator.languages.map(localeForTag);

export const translatorFor = (locale: Intl.Locale): Translator => {
  const trans = translations[locale.baseName] || translations[locale.language];

  const t: Translator = (key, opts = {}) => {
    const val = trans[key];
    if (!val) return `key '${key}' not found.`;
    if (typeof val === 'function') {
      return val(opts);
    }
    return val;
  };

  t.toSentence = (items: any[]) => {
    const size = items.length;
    const wordsConnector = t('support.array.wordsConnector');

    switch (size) {
    case 0:
      return <></>;
    case 1:
      return <>{items[0]}</>;
    case 2:
      return <>{items[0]}{t('support.array.twoWordsConnector')}{items[1]}</>;
    default:
      return [
        items.slice(0, size - 1).reduce((acc, i) => acc === null ? [i] : [acc, wordsConnector , i], null),
        t('support.array.lastWordConnector'),
        items[size - 1]
      ];
    }
  };

  t.intervalFor = ({ interval, length }: Period) => {
    if (interval === 'months') {
      if (length === 12) {
        return t('year');
      } else if (length === 1) {
        return t('month');
      } else {
        return `${length} ${t('months')}`;
      }
    }

    return `${length} ${interval}`;
  };

  return t;
};

export const preferredCurrency = (defaultCurrency: string, currencies: Currency[]): Currency => {
  const browserLocaleCurrencyCode = LocaleCurrency.getCurrency(browserLocale().baseName);

  if (currencies.length > 0) {
    return currencies.find(c => c.code === browserLocaleCurrencyCode)
        || currencies.find(c => c.code === defaultCurrency)
        || currencies[0];
  } else {
    return { code: defaultCurrency };
  }
};

export const preferredLocaleTag = () => {
  for (const locale of browserLanguageLocales()) {
    if (languageSupported(locale)) return locale.baseName;
  }

  return `en-${browserLocale().region}`;
};
