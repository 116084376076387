import React from 'react';
export default {
  'cart': {
    'change-currency': 'Ändra valuta',
    'and-then': 'Och sedan',
    'plus-tax': ' + skatt',
    'for': 'För',
    'edit': {
      'label': 'Redigera',
      'submit': 'Tillämpa'
    },
    'empty': 'Du har inte lagt till något i din beställning än.',
    'free': 'gratis',
    'promo-code': {
      'add': 'Lägg till presentkort eller kampanjkod',
      'label': 'Presentkort eller kampanjkod',
      'submit': 'Tillämpa'
    },
    'subtotal-line': {
      'tax-label': 'Uppskattad skatt',
      'subtotal': 'Delsumma',
      'tax': 'Skatt',
      'gift-card': 'Present kort'
    },
    'starting-price': 'Startpris',
    'title': 'Ordersammanfattning',
    'setup-fee': 'installationsavgift',
    'free-trial': 'Gratis provperiod',
    'total-line': {
      'label': 'Totalt'
    },
    'order-total-line': {
      'label': 'Order Summa'
    }
  },
  'address': {
    'country': 'Land',
    'address2': 'Lägenhet, svit, etc.',
    'tax-identifier': 'Skatteidentifiering',
    'tax-identifier-type': 'Typ av skatteidentifiering',
    'first-name': 'Förnamn',
    'last-name': 'Efternamn',
    'company-name': 'Företagsnamn',
    'address1': 'Gatuadress',
    'city': 'Stad',
    'state': 'Stat',
    'postal-code': 'Zip/Post',
    'phone-number': 'Telefonnummer',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Kortuppgifter',
      'expiry': 'MM/ÅÅ',
      'name': 'Kreditkort',
      'number': 'Kortnummer',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Bankkonto',
      'routing-number': 'Clearingnummer',
      'account-number': 'Kontonummer',
      'account-number-confirmation': 'Bekräfta kontonummer',
      'account-type': {
        'label': 'Kontotyp',
        'checking': 'Kontroll',
        'savings': 'Besparingar'
      },
      'name-on-account': 'Namn på konto'
    },
    'amazon': {
      'name': 'Amazon Pay'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Clearing nummer'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB-kod'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Faktureringsadress',
  'billing-address-same-as-shipping': 'Samma som leveransadress',
  'submit': 'Betala nu',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} vald</>,
  'express-checkout': 'Expressutcheckning',
  'change-payment-method': 'Ändra betalmetod',
  'day': 'dag',
  'days': 'dagar',
  'week': 'vecka',
  'weeks': 'veckor',
  'month': 'månad',
  'months': 'månader',
  'year': 'år',
  'years': 'år',
  'confirmation': {
    'title': 'Tack för din beställning',
    'order-num': ({ number }) => <>Ordernummer {number}</>,
    'fulfilled-on': ({ date }) => <>Avslutad den {date}</>,
    'email-notice': 'Du kommer att få en e-postbekräftelse på din beställning.',
    'payment': 'Betalning',
    'return-to': ({ name }) => <>Återgå till {name}</>,
    'credit-applied': 'Kredit Tillämpad'
  },
  'back': 'Tillbaka',
  'contact-info': 'Kontaktinformation',
  'email-address': 'E-post',
  'payment-info': 'Faktureringsinformation',
  'shipping-address': 'Leveransadress',
  'legal': {
    'accept': 'Jag accepterar',
    'privacy-policy': 'Integritetspolicy',
    'terms-of-service': 'Användarvillkor'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' och ',
      'lastWordConnector': ' och '
    }
  },
  'powered-by': ({ recurly }) => <>Prenumerationsfakturering drivs av {recurly}</>,
  'errors': {},
  'subscribe': 'Prenumerera'
};
