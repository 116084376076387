import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { CART_EMPTINESS_STATE, CartContext } from '@/contexts/cart-context';
import { Currency } from '@/components/price';
import { FieldError } from '@/components/form';
import { preferredCurrency } from '@/services/locale';
import { useCheckoutSession } from '@/hooks/use-checkout-session';

import type { CartSubscription, CartLineItem } from '@/services/session';

export type CartFormError = {
  promoCode?: FieldError
};

type CartInternalContextType = {
  currency?: Currency;
  error?: CartFormError;
  open: boolean;
  setError: Function;
  setOpen: Function;
  showCartEdits: boolean;
  setShowCartEdits: Function;
  canApplyPromoCode: boolean;
  setCanApplyPromoCode: Function;
};

export const CartInternalContext = createContext<CartInternalContextType>({
  open: false,
  setError: () => {},
  setOpen: () => {},
  showCartEdits: false,
  setShowCartEdits: () => {},
  canApplyPromoCode: true,
  setCanApplyPromoCode: () => {},
});

const quantityFor = (
  maybeQuantity: string
) => {
  // if the item does not have a quantity, it will present as an empty string
  if (maybeQuantity === '') {
    return 1;
  }

  if (isNaN(Number(maybeQuantity))) {
    return 1;
  }

  return Number(maybeQuantity);
};

const excludeZeroQuantityItems = (items: CartSubscription[] | CartLineItem[]) => (
  items.filter(i => Number(i.quantity) !== 0)
);

export function CartInternalContextProvider ({ children }: PropsWithChildren) {
  const [checkoutSession, updateCheckoutSession] = useCheckoutSession();
  const [error, setError] = useState<CartFormError>({});
  const [open, setOpen] = useState(false);
  const [showCartEdits, setShowCartEdits] = useState(false);
  const [canApplyPromoCode, setCanApplyPromoCode] = useState(true);
  const {
    billingAddress,
    price,
    priceLoading,
    setCartEmptiness,
    setCheckoutPricing,
    shippingAddress
  } = useContext(CartContext);

  const {
    cart: {
      coupons = [],
      currencies,
      currency,
      giftCards = [],
      lineItems = [],
      subscriptions = []
    },
    site: {
      defaultCurrency
    },
  } = checkoutSession;

  // Update pricing 
  useEffect(() => {
    setCheckoutPricing({
      address: billingAddress,
      adjustments: lineItems.map(l => ({
        currency: currency?.code,
        id: l.ephemeralId,
        itemCode: l.itemId,
        quantity: quantityFor(l.quantity)
      })),
      coupon: coupons.length > 0 ? coupons[0].code : undefined,
      currency: currency?.code,
      giftCard: giftCards.length > 0 ? giftCards[0].code : undefined,
      shippingAddress,
      subscriptions: subscriptions.map(s => ({
        addons: s.addOns.map(({ code, quantity = 1 }) => ({ code, quantity: quantityFor(quantity) })),
        id: s.ephemeralId,
        plan: s.planId,
        quantity: quantityFor(s.quantity)
      }))
    });
    // TODO determine how coupon and giftCard changes trigger a pricing update
  }, [billingAddress, currency, lineItems, shippingAddress, subscriptions]);

  // Handle a new session with no currency set
  useEffect(() => {
    if (currency?.code) return;

    updateCheckoutSession({
      ...checkoutSession,
      cart: {
        ...checkoutSession.cart,
        currency: preferredCurrency(defaultCurrency, currencies)
      }
    });
  }, []);

  // Update the empty cart state after loading
  useEffect(() => {
    if (priceLoading) return;
    if (subscriptions.length === 0 && lineItems.length === 0) {
      setCartEmptiness(CART_EMPTINESS_STATE.NO_ITEMS);
    } else if (
      excludeZeroQuantityItems(subscriptions).length === 0
      && excludeZeroQuantityItems(lineItems).length === 0
    ) {
      setCartEmptiness(CART_EMPTINESS_STATE.ZERO_ITEMS);
    } else {
      setCartEmptiness(CART_EMPTINESS_STATE.NOT_EMPTY);
    }
  }, [priceLoading, subscriptions, lineItems]);

  return (
    <CartInternalContext.Provider
      value={{
        currency: { ...currency, ...price.currency },
        error,
        open,
        setError,
        setOpen,
        showCartEdits,
        setShowCartEdits,
        canApplyPromoCode,
        setCanApplyPromoCode,
      }}
    >
      {children}
    </CartInternalContext.Provider>
  );
}
