import React from 'react';
export default {
  'cart': {
    'change-currency': 'Verander munteenheid',
    'and-then': 'En dan',
    'plus-tax': ' + btw',
    'for': 'Voor',
    'edit': {
      'label': 'Bewerken',
      'submit': 'Toepassen'
    },
    'empty': 'Je hebt nog niets aan je bestelling toegevoegd.',
    'free': 'gratis',
    'promo-code': {
      'add': 'Voeg cadeaukaart of promocode toe',
      'label': 'Cadeaukaart of promocode',
      'submit': 'Toepassen'
    },
    'subtotal-line': {
      'tax-label': 'Geschatte btw',
      'subtotal': 'Subtotaal',
      'tax': 'belasting',
      'gift-card': 'Cadeaukaart'
    },
    'starting-price': 'Beginprijs',
    'title': 'Besteloverzicht',
    'setup-fee': 'vergoeding voor opzetten',
    'free-trial': 'Gratis proefperiode',
    'total-line': {
      'label': 'Totaal'
    },
    'order-total-line': {
      'label': 'Volledige Bestelling'
    }
  },
  'address': {
    'country': 'Land',
    'address2': 'Appartement, suite enzovoort',
    'tax-identifier': 'Belasting-ID',
    'tax-identifier-type': 'Type belastingidentificatie',
    'first-name': 'Voornaam',
    'last-name': 'Achternaam',
    'company-name': 'Bedrijfsnaam',
    'address1': 'Straatadres',
    'city': 'Stad/Woonplaats',
    'state': 'Provincie',
    'postal-code': 'postcode',
    'phone-number': 'Telefoonnummer',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Kaartgegevens',
      'expiry': 'MM/JJ',
      'name': 'Credit Card',
      'number': 'Credit Card Nummer',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Bankrekening',
      'routing-number': 'Routing Number',
      'account-number': 'Rekening Nummer',
      'account-number-confirmation': 'Bevestig Account Number',
      'account-type': {
        'label': 'Account Type',
        'checking': 'Account Controleren',
        'savings': 'Spaarrekening'
      },
      'name-on-account': 'Naam op Account'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Factuuradres',
  'billing-address-same-as-shipping': 'Hetzelfde als leveringsadres',
  'submit': 'Nu betalen',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} geselecteerd</>,
  'express-checkout': 'Snel afrekenen',
  'change-payment-method': 'Betaalmethode wijzigen',
  'day': 'dag',
  'days': 'dagen',
  'week': 'week',
  'weeks': 'weken',
  'month': 'maand',
  'months': 'maanden',
  'year': 'jaar',
  'years': 'jaren',
  'confirmation': {
    'title': 'Bedankt voor uw bestelling',
    'order-num': ({ number }) => <>Bestel #{number}</>,
    'fulfilled-on': ({ date }) => <>Voltooid op {date}</>,
    'email-notice': 'U ontvangt per e-mail een bevestiging van uw bestelling.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Terugzenden aan {name}</>,
    'credit-applied': 'Krediet toegepast'
  },
  'back': 'Terug',
  'contact-info': 'Contact Informatie',
  'email-address': 'email',
  'payment-info': 'Informatie Facturering',
  'shipping-address': 'Afleveradres',
  'legal': {
    'accept': 'Ik accepteer de',
    'privacy-policy': 'Privacy Beleid',
    'terms-of-service': 'Voorwaarden van Dienstverlening'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' en ',
      'lastWordConnector': ' en '
    }
  },
  'powered-by': 'Facturering abonnement mogelijk gemaakt door {recurly}',
  'errors': {},
  'subscribe': 'Abonneer'
};
