import React, { useEffect, useState } from 'react';

import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { useLocale } from '@/hooks/use-locale';
import { Customer } from '@/services/purchase';
import { Field, TextInput } from '../form';
import { FLAGS } from '@/services/session';
import { CheckoutAddress, AutoCompletePrefix } from '@/types/checkout-address';
import { ADDRESS_FIELD_NAME, autoCompleteValueFor, nameFor } from '@/utils/address-utils';

export function FirstAndLastName ({
  autoCompletePrefix,
  namePrefix = '',
  onCustomerChange = () => {},
  onAddressChange = () => {},
  customer,
  address
}: {
  autoCompletePrefix?: AutoCompletePrefix;
  namePrefix?: string;
  onCustomerChange: (_customer: Customer) => void,
  onAddressChange: (_address: CheckoutAddress) => void,
  customer?: Customer,
  address?: CheckoutAddress
}) {
  const [{ flags }] = useCheckoutSession();
  const { t } = useLocale();
  const [company, setCompany] = useState(address?.company || '');
  const [firstName, setFirstName] = useState(customer?.firstName || '');
  const [lastName, setLastName] = useState(customer?.lastName || '');
  const [vatNumber, setVatNumber] = useState(address?.vatNumber || '');

  useEffect(() => setCompany(address?.company || ''), [address?.company]);
  useEffect(() => setFirstName(customer?.firstName || ''), [customer?.firstName]);
  useEffect(() => setLastName(customer?.lastName || ''), [customer?.lastName]);
  useEffect(() => setVatNumber(address?.vatNumber || ''), [address?.vatNumber]);

  return (
    <>
      <Field
        label={t('address.first-name')}
        name={nameFor(ADDRESS_FIELD_NAME.FIRST_NAME, namePrefix)}
        className="col-span-2 xs:col-span-1"
        required
      >
        <TextInput
          autoComplete={autoCompleteValueFor('given-name', autoCompletePrefix)}
          onBlur={() => onCustomerChange({ firstName })}
          onChange={event => setFirstName(event.target.value)}
          value={firstName}
        />
      </Field>

      <Field
        label={t('address.last-name')}
        name={nameFor(ADDRESS_FIELD_NAME.LAST_NAME, namePrefix)}
        className="col-span-2 xs:col-span-1"
        required
      >
        <TextInput
          autoComplete={autoCompleteValueFor('family-name', autoCompletePrefix)}
          onBlur={() => onCustomerChange({ lastName })}
          onChange={event => setLastName(event.target.value)}
          value={lastName}
        />
      </Field>

      {flags.includes(FLAGS.ACCEPT_COMPANY_NAME) && (
        <Field
          label={t('address.company-name')}
          name={nameFor(ADDRESS_FIELD_NAME.COMPANY, namePrefix)}
          className="col-span-2"
        >
          <TextInput
            autoComplete={autoCompleteValueFor('organization', autoCompletePrefix)}
            onBlur={() => onAddressChange({ company })}
            onChange={event => setCompany(event.target.value)}
            value={company}
          />
        </Field>
      )}

      {flags.includes(FLAGS.ACCEPT_VAT_NUMBER) && (
        <Field
          label={t('address.vat-number')}
          name={nameFor(ADDRESS_FIELD_NAME.VAT_NUMBER, namePrefix)}
          className="col-span-2"
        >
          <TextInput
            onBlur={() => onAddressChange({ vatNumber })}
            onChange={event => setVatNumber(event.target.value)}
            value={vatNumber}
          />
        </Field>
      )}
    </>
  );
}
