import React, { useContext, useEffect, useRef } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { AdyenCredentials } from '@/services/session';
import { PaymentMethodContext, PaymentMethodSelectorContext } from '../payment-methods';
import { PaymentMethodOption } from './option';
import { useLocale } from '@/hooks/use-locale';
import { useCheckoutSession } from '@/hooks/use-checkout-session';

const SOFORT_CURRENCY_CODES = ['EUR', 'CHF'];
const SOFORT_COUNTRY_CODES = ['AT', 'BE', 'DE', 'ES', 'CH', 'NL'];

export function SofortPaymentMethodOption () {
  const { t, locale } = useLocale();
  const recurly = useRecurly();
  const targetRef = useRef(document.createElement('div'));
  const [{ cart: { currency } }] = useCheckoutSession();
  const { setError } = useContext(PaymentMethodSelectorContext);
  const {
    billingAddress,
    paymentMethod,
    paymentMethodForType,
    pricing,
    setBillingInfoTokenCreator
  } = useContext(PaymentMethodContext);
  const sofortPaymentMethod = paymentMethodForType('sofort');
  const adyenGateway = sofortPaymentMethod?.gateways.find(({ type }) => type === 'adyen');

  useEffect(() => {
    if (!adyenGateway) return;
    if (!SOFORT_CURRENCY_CODES.includes(currency.code)) return;
    if (!targetRef?.current) return;
    if (paymentMethod?.type !== sofortPaymentMethod?.type) return;

    const countryCode = SOFORT_COUNTRY_CODES.includes(billingAddress?.country)
      ? billingAddress?.country
      : 'CH';

    const sofort = recurly.AlternativePaymentMethods({
      allowedPaymentMethods: ['sofort'],
      blockedPaymentMethods: [],
      containerSelector: targetRef?.current,
      amount: pricing?.price?.now?.total,
      currency: currency.code,
      countryCode,
      locale: locale.baseName,
      channel: 'Web',
      adyen: {
        publicKey: (adyenGateway.credentials as AdyenCredentials).clientKey,
        showPayButton: false,
        componentConfig: {}
      },
      returnURL: window.location.href
    });

    sofort.on('error', (error) => setError(error));

    sofort.start();

    setBillingInfoTokenCreator(() => (form, done) => {
      sofort.once('token', (token) => done(undefined, token));
      sofort.submit({ billingAddress });
    });
  }, [currency, targetRef.current, paymentMethod, pricing]);

  if (!SOFORT_CURRENCY_CODES.includes(currency.code)) return <></>;

  return (
    <PaymentMethodOption title={t('payment-method.sofort.name')} type="sofort">
      <div ref={targetRef}></div>
    </PaymentMethodOption>
  );
}
