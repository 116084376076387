import React from 'react';
export default {
  'cart': {
    'change-currency': 'Alterar moeda',
    'and-then': 'E depois',
    'plus-tax': ' + impostos',
    'for': 'Para',
    'edit': {
      'label': 'Editar',
      'submit': 'Aplicar'
    },
    'empty': 'Você ainda não adicionou nada no seu pedido.',
    'free': 'grátis',
    'promo-code': {
      'add': 'Adicione um cartão-presente ou código promocional',
      'label': 'Cartão-presente ou código promocional',
      'submit': 'Aplicar'
    },
    'subtotal-line': {
      'tax-label': 'Imposto estimado',
      'subtotal': 'Sub-total',
      'tax': 'imposto',
      'gift-card': 'cartão presente'
    },
    'starting-price': 'Preço inicial',
    'title': 'Resumo do pedido',
    'setup-fee': 'taxa de configuração',
    'free-trial': 'teste grátis',
    'total-line': {
      'label': 'Total'
    },
    'order-total-line': {
      'label': 'Total da Encomenda'
    }
  },
  'address': {
    'country': 'País',
    'address2': 'Apartamento, suíte etc.',
    'tax-identifier': 'Identificador fiscal',
    'tax-identifier-type': 'Tipo de identificador fiscal',
    'first-name': 'Primeiro nome',
    'last-name': 'Último nome',
    'company-name': 'Nome da Empresa',
    'address1': 'Endereço:',
    'city': 'Cidade',
    'state': 'Província',
    'postal-code': 'Código Postal',
    'phone-number': 'Número de telefone',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Dados do cartão',
      'expiry': 'MM/AA',
      'name': 'Cartão De Crédito',
      'number': 'Número do Cartão de Crédito',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Conta Bancária',
      'routing-number': 'Número Routing',
      'account-number': 'Número De Conta',
      'account-number-confirmation': 'Confirme Número de Conta',
      'account-type': {
        'label': 'Tipo De Conta',
        'checking': 'Conta Corrente',
        'savings': 'Caderneta de poupança'
      },
      'name-on-account': 'Nome em Conta'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Endereço de cobrança',
  'billing-address-same-as-shipping': 'Igual ao endereço de entrega',
  'submit': 'Pagar agora',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} selecionado</>,
  'express-checkout': 'Compra Expressa',
  'change-payment-method': 'Mudar modo de pagamento',
  'day': 'dia',
  'days': 'dias',
  'week': 'semana',
  'weeks': 'semanas',
  'month': 'mês',
  'months': 'meses',
  'year': 'ano',
  'years': 'anos',
  'confirmation': {
    'title': 'Obrigado pelo seu pedido',
    'order-num': ({ number }) => <>Pedido #{number}</>,
    'fulfilled-on': ({ date }) => <>Concluído em {date}</>,
    'email-notice': 'Você receberá um e-mail de confirmação do seu pedido.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Devolver a {name}</>,
    'credit-applied': 'Aplicada Crédito'
  },
  'back': 'Voltar',
  'contact-info': 'Informação de Contacto',
  'email-address': 'o email',
  'payment-info': 'Informação de Faturação',
  'shipping-address': 'Endereço de entrega',
  'legal': {
    'accept': 'Aceito o',
    'privacy-policy': 'Política de Privacidade',
    'terms-of-service': 'Termos de Serviço'
  },
  'support': {
    'array': {
      'wordsConnector': ',',
      'twoWordsConnector': 'e',
      'lastWordConnector': ', e'
    }
  },
  'powered-by': ({ recurly }) => <>Faturação da assinatura impulsionada por {recurly}</>,
  'errors': {},
  'subscribe': 'Subscrever'
};
