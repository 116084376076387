import React, { useEffect, useState } from 'react';
import { RecurlyError, TokenPayload } from '@recurly/recurly-js';
import { ThreeDSecureAction } from '@recurly/react-recurly';
import { twMerge } from 'tailwind-merge';

import { Button } from '../form';

export function ThreeDSecureModal ({
  actionTokenId,
  onCancel,
  onError,
  onToken
}: {
  actionTokenId: string;
  onCancel: () => void;
  onError: (_error: RecurlyError) => void;
  onToken: (_token: TokenPayload) => void;
}) {
  const [loading, setLoading] = useState(true);
  const [canceling, setCanceling] = useState(false);

  useEffect(() => {
    document.body.classList.add('body-anti-scroll');
    return () => document.body.classList.remove('body-anti-scroll');
  }, []);

  return (
    <>
      <div className="
        bg-gray-700/60
        overflow-y-auto overflow-x-hidden
        fixed top-0 right-0 left-0 z-40
        justify-center items-center
        w-full md:inset-0 h-full max-h-full
      ">
        <div className="relative mx-auto max-w-full xs:max-w-[454px]">
          <div className="relative bg-checkout xs:rounded-md p-4 xs:m-4">
            <h2 className="mb-4">{loading ? 'Verifying...' : 'Verification needed'}</h2>
            {loading && (
              <div className="grid grid-cols-4 gap-4 animate-pulse">
                <div className="h-4 rounded bg-gray-200 dark:bg-gray-600 col-span-3"></div>
                <div className="h-4 col-span-1"></div>
                <div className="h-4 rounded bg-gray-200 dark:bg-gray-600 col-span-1"></div>
                <div className="h-4 rounded bg-gray-200 dark:bg-gray-600 col-span-3"></div>
              </div>
            )}
            <div className={twMerge(
              'overflow-scroll h-full xs:h-auto',
              !loading && 'xs:h-[400px]'
            )}>
              <ThreeDSecureAction
                actionTokenId={actionTokenId}
                onError={error => onError(error)}
                onReady={() => setLoading(false)}
                {...{ onToken }}
              />
            </div>
            <div className="flex justify-end">
              <Button
                className="mt-4"
                onClick={() => setCanceling(true)}
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>

      {canceling && (
        <div className="bg-gray-700/60 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full">
          <div className="relative p-8 w-full max-w-sm max-h-full mx-auto">
            <div className="relative bg-checkout rounded-md p-4">
              <h2 className="mb-4 font-bold">
                Are you sure you want to cancel verification?
              </h2>
              <p>
                Verification is required to complete this purchase.
              </p>
              <div className="grid grid-cols-2 space-x-2">
                <Button className="mt-4" onClick={() => onCancel()}>
                  Confirm
                </Button>
                <Button className="mt-4" onClick={() => setCanceling(false)} variant="secondary">
                  Keep verifying
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
