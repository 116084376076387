import React from 'react';
export default {
  'cart': {
    'change-currency': 'Changer la devise',
    'and-then': 'Puis',
    'plus-tax': ' + les taxes',
    'for': 'Pour',
    'edit': {
      'label': 'Modifier',
      'submit': 'Appliquer'
    },
    'empty': "Vous n'avez encore rien ajouté à votre commande.",
    'free': 'gratuit',
    'promo-code': {
      'add': 'Ajouter une carte-cadeau ou un code promo',
      'label': 'Carte-cadeau ou code promo',
      'submit': 'Appliquer'
    },
    'subtotal-line': {
      'tax-label': 'Estimation des taxes',
      'subtotal': 'Sous-total',
      'tax': 'Taxe',
      'gift-card': 'Carte Cadeau'
    },
    'starting-price': 'Prix de départ',
    'title': 'Résumé de la commande',
    'setup-fee': 'frais de mise en place',
    'free-trial': 'essai gratuit',
    'total-line': {
      'label': 'Total'
    },
    'order-total-line': {
      'label': 'Total de la commande'
    }
  },
  'address': {
    'country': 'Pays',
    'address2': 'Appartement, suite, etc.',
    'tax-identifier': 'Identifiant fiscal',
    'tax-identifier-type': "Type d'identifiant fiscal",
    'first-name': 'Prénom',
    'last-name': 'Nom de famille',
    'company-name': "Nom de l'entreprise",
    'address1': 'Adresse',
    'city': 'Ville',
    'state': 'Province',
    'postal-code': 'Code postal',
    'phone-number': 'Numéro de téléphone',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Informations de la carte',
      'expiry': 'MM/AA',
      'name': 'Carte De Crédit',
      'number': 'Numéro de carte de crédit',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Compte Bancaire',
      'routing-number': 'Nombre de routage',
      'account-number': 'Numéro de compte',
      'account-number-confirmation': 'Confirmez le numéro de compte',
      'account-type': {
        'label': 'Type de compte',
        'checking': 'Compte Courant',
        'savings': "Compte d'épargne"
      },
      'name-on-account': 'Nom sur le compte'
    },
    'amazon': {
      'name': 'Amazon'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Adresse de facturation',
  'billing-address-same-as-shipping': 'Identique à l’adresse de livraison',
  'submit': 'Payer maintenant',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} sélectionné</>,
  'express-checkout': 'Paiement express',
  'change-payment-method': 'Changer le mode de paiement',
  'day': 'jour',
  'days': 'jours',
  'week': 'semaine',
  'weeks': 'semaines',
  'month': 'mois',
  'months': 'mois',
  'year': 'année',
  'years': 'années',
  'confirmation': {
    'title': 'Nous vous remercions pour votre commande',
    'order-num': ({ number }) => <>Commande #{number}</>,
    'fulfilled-on': ({ date }) => <>Effectuée le {date}</>,
    'email-notice': 'Vous recevrez un e-mail de confirmation de votre commande.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Revenir à {name}</>,
    'credit-applied': 'Crédit Appliquée'
  },
  'back': 'Retour',
  'contact-info': 'Coordonnées',
  'email-address': 'email',
  'payment-info': 'Renseignements sur la facturation',
  'shipping-address': 'Adresse de livraison',
  'legal': {
    'accept': "J'accepte le",
    'privacy-policy': 'Politique de confidentialité',
    'terms-of-service': 'Conditions de service'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' et ',
      'lastWordConnector': ' et '
    }
  },
  'powered-by': ({ recurly }) => <>Abonnement facturé par {recurly}</>,
  'errors': {},
  'subscribe': "S'abonner"
};
