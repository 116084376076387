import camelcaseKeys from 'camelcase-keys';
import debug from 'debug';
import { Address, RecurlyError, TokenPayload } from '@recurly/recurly-js';
import { RefObject } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { Currency } from '@/components/price';
import { FieldError } from '@/components/form';
import { PriceObject } from '@/components/price';
import { RecurlyWithInternals } from '@/types/recurly';
import { ADDRESS_FIELD_NAME } from '@/utils/address-utils';

export type Customer = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  emailAddressPresent?: string;
};

export type TransactionPaymentMethod = {
  type: 'ach'
      | 'amazon'
      | 'apple_pay'
      | 'bacs'
      | 'becs'
      | 'credit_card'
      | 'google_pay'
      | 'paypal'
      | 'sepa';
  cardBrand: 'american_express'
           | 'diners_club'
           | 'discover'
           | 'gateway_token'
           | 'jcb'
           | 'maestro'
           | 'master'
           | 'visa'
           | 'union_pay'
           | 'elo'
           | 'hipercard'
           | 'tarjeta_naranja'
           | 'cartes_bancaires'
           | 'bancontact'
           | 'dankort';
  cardBrandName: string;
  lastFour: string;
};

export type Period = {
  interval: string;
  length: number;
}

type Plan = {
  period: Period;
  trial: Period;
};

type CouponRedemption = {
  coupon: {
    code: string;
  }
};

export type LineItem = {
  addOnId?: string;
  amount: PriceObject;
  description: string;
  origin: 'plan'
        | 'plan_trial'
        | 'setup_fee'
        | 'add_on'
        | 'add_on_trial'
        | 'usage_add_on'
        | 'usage_add_on_trial'
        | 'one_time'
        | 'debit'
        | 'credit'
        | 'coupon'
        | 'carryforward'
        | 'gift_card'
        | 'external_gift_card'
        | 'shipping'
        | 'prepayment';
  plan?: Plan;
  quantity: string;
  recurring: boolean;
  subscriptionId?: string;
  subtotal: PriceObject;
  type: 'charge' | 'credit';
  unitAmount: PriceObject;
};

type Transaction = {
  paymentMethod: TransactionPaymentMethod;
};

export type Invoice = {
  account: Customer;
  billingAddress?: Address;
  couponRedemptions: CouponRedemption[];
  createdAt: string;
  credit: PriceObject;
  discount: PriceObject;
  lineItems: LineItem[];
  number: string;
  paid: PriceObject;
  shippingAddress?: Address;
  subtotal: PriceObject;
  tax: PriceObject;
  total: PriceObject;
  transactions: Transaction[];
  type: 'charge' | 'credit';
};

export type Purchase = {
  invoices: Invoice[];
};

type RecurlyThreeDSecureError = {
  three_d_secure_action_token_id: string;
} & RecurlyError;

type RecurlyReCaptchaError = {
  recaptcha_client_key: string;
} & RecurlyError;

/**
 * TODO
 *
 * FormError has many concerns related to shipping address error translation
 *
 * 1. Write shipping address error translation into the CheckoutPurchaseBuilder
 * 2. Remove these concerns here
 */
export class FormError extends Error {
  private SHIPPING_ADDRESS_PATTERN = /^shipping_address\.(\w+)$/;
  private log = debug('checkout:purchase-error');

  from: RecurlyError;
  base?: FieldError;
  fields: FieldError[];

  constructor (apiError: RecurlyError) {
    super(apiError?.message);

    this.fields = [];
    this.log({ apiError, self: this });

    if (!apiError) return;

    this.from = apiError;
    this.base = new FieldError('base', [apiError.message]);

    if (apiError.details) {
      for (const { field, messages } of apiError.details) {
        this.setFieldError(field, messages);
      }
    }
  }

  public static withDuplicatedAddressFields (formError: FormError, prefixer: (_name: string) => string) {
    const newFormError = new FormError(formError.from);
    const addressFieldNames = Object.values(ADDRESS_FIELD_NAME);
    const newFieldErrors = newFormError.fields
      .filter((fe: FieldError) => addressFieldNames.includes(fe.name))
      .map((fe: FieldError) => {
        fe.name = prefixer(fe.name);
        return fe;
      });
    newFormError.fields = newFormError.fields.concat(newFieldErrors);
    return newFormError;
  }

  public getFieldError (field: string): FieldError | undefined {
    return this.fields.find(fe => fe.name === field);
  }

  public addressErrorsPresent (prefix = '') {
    const addressFieldNames = Object.values(ADDRESS_FIELD_NAME).map(n => `${prefix}${n}`);
    return this.fields.some(f => addressFieldNames.includes(f.name));
  }

  private setFieldError (field: string, messages: string[]) {
    let stackErrors = false;

    const shippingAddressMatch = field.match(this.SHIPPING_ADDRESS_PATTERN);

    if (shippingAddressMatch) {
      const shippingAddressField = shippingAddressFieldFor(shippingAddressMatch);
      field = `shipping[address][${shippingAddressField}]`;
    } else if (['number', 'cvv', 'month'].includes(field)) {
      messages = messages.map(m => field === 'month' ? `expiry ${m}` : `${field} ${m}`);
      field = 'card';
      stackErrors = true;
    } else if (field === 'year') {
      return;
    }

    const existingFieldError = this.getFieldError(field);

    if (stackErrors) {
      messages = (existingFieldError?.messages || []).concat(messages);
    }

    this.fields = this.fields.filter(fe => fe !== existingFieldError).concat(
      [new FieldError(field, messages)]
    );
  }
}

export class ThreeDSecureRequiredError extends Error {
  private log = debug('checkout:three-d-secure-error');

  threeDSecureActionTokenId: string;

  constructor (apiError: RecurlyThreeDSecureError) {
    super(apiError?.message);

    this.log({ apiError, self: this });

    this.threeDSecureActionTokenId = apiError.three_d_secure_action_token_id;
  }
}

export class ReCaptchaRequiredError extends FormError {
  reCaptchaClientKey: string;

  constructor (apiError: RecurlyReCaptchaError) {
    super(apiError);

    this.reCaptchaClientKey = apiError.recaptcha_client_key;
  }
}

export class PurchaseCreator {
  constructor (
    private billingInfoToken: TokenPayload | undefined,
    private billingInfoTokenCreator: (() => TokenPayload) | undefined,
    private checkoutSessionTokenId: string,
    private formRef: RefObject<HTMLFormElement>,
    private recurly: RecurlyWithInternals
  ) {
    this.billingInfoToken = billingInfoToken;
    this.billingInfoTokenCreator = billingInfoTokenCreator;
    this.checkoutSessionTokenId = checkoutSessionTokenId;
    this.formRef = formRef;
    this.recurly = recurly;
  }

  public async create (
    currency: Currency,
    customer: Customer,
    shippingAddress?: Address,
    threeDSecureActionResultTokenId?: string,
    reCaptchaResult?: string,
    policiesAcceptance?: boolean,
    requiresBillingInfo?: boolean
  ) {
    const customerData = snakecaseKeys(
      {
        customer,
        policiesAcceptance,
        shipping: {
          address: shippingAddress
        }
      },
      { deep: true }
    );

    try {
      let billingInfoTokenId;

      if (requiresBillingInfo) {
        billingInfoTokenId = (this.billingInfoToken || await this.createBillingInfoToken()).id;
      }

      return camelcaseKeys(
        await this.recurly.request.post({
          route: '/purchases',
          data: {
            purchase: {
              billing_info: {
                token_id: billingInfoTokenId,
                three_d_secure_action_result_token_id: threeDSecureActionResultTokenId
              },
              checkout_session: {
                token_id: this.checkoutSessionTokenId,
              },
              currency,
              ...customerData
            },
            'g-recaptcha-response': reCaptchaResult
          }
        }),
        { deep: true }
      );
    } catch (error) {
      if ('three_d_secure_action_token_id' in error) {
        throw new ThreeDSecureRequiredError(error as RecurlyThreeDSecureError);
      }

      if (error?.code === 'recaptcha-required') {
        throw new ReCaptchaRequiredError(error as RecurlyReCaptchaError);
      }

      throw new FormError(error as RecurlyError);
    }
  }

  private async createBillingInfoToken (): Promise<TokenPayload> {
    const { current: form } = this.formRef;
    const tokenMethod: Function = this.billingInfoTokenCreator || this.recurly.token;

    if (!form) throw new Error('No form');

    return new Promise((resolve, reject) => tokenMethod(form, (error: RecurlyError, token: TokenPayload) => {
      if (error) return reject(camelcaseKeys(error));
      resolve(camelcaseKeys(token));
    }));
  }
}

function shippingAddressFieldFor (shippingAddressMatch: RegExpMatchArray): string {
  const fieldName = shippingAddressMatch[1];

  if (fieldName === 'zip') {
    return 'postal_code';
  }

  return fieldName;
}
