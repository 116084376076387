{
  "name": "checkout-ui",
  "version": "0.0.1",
  "private": true,
  "description": "Recurly Checkout UI",
  "main": "src/index.tsx",
  "type": "module",
  "repository": {
    "type": "git",
    "url": "https://github.com/recurly/checkout-ui"
  },
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "@eslint/eslintrc": "^3.1.0",
    "@eslint/js": "^9.9.1",
    "@playwright/test": "^1.46.1",
    "@recurly/public-api-test-server": "https://gitpkg.now.sh/recurly/recurly-js/packages/public-api-fixture-server?028f499",
    "@testing-library/jest-dom": "^6.5.0",
    "@testing-library/react": "^16.0.0",
    "@types/applepayjs": "^14.0.8",
    "@types/debug": "^4.1.10",
    "@types/jest": "^29.5.4",
    "@types/node": "^22.5.1",
    "@types/react": "^18.2.21",
    "@types/react-dom": "^18.2.7",
    "@types/react-google-recaptcha": "^2.1.9",
    "@types/react-router-dom": "^5.3.3",
    "@types/recurly__recurly-js": "^4.25.1",
    "@types/testing-library__jest-dom": "^6.0.0",
    "@typescript-eslint/parser": "^8.3.0",
    "autoprefixer": "^10.4.15",
    "csv": "^6.3.8",
    "esbuild": "^0.23.1",
    "esbuild-jest": "^0.5.0",
    "esbuild-style-plugin": "^1.6.2",
    "eslint": "^9.9.1",
    "globals": "^15.9.0",
    "jest": "^29.6.4",
    "jest-environment-jsdom": "^29.6.4",
    "postcss": "^8.4.28",
    "postcss-font-magician": "^4.0.0",
    "postcss-preset-env": "^10.0.2",
    "type-fest": "^4.4.0",
    "typescript": "^5.2.2"
  },
  "dependencies": {
    "@recurly/react-recurly": "github:recurly/react-recurly#next",
    "camelcase-keys": "^9.0.0",
    "countries-list": "^3.0.5",
    "debug": "^4.3.4",
    "deepmerge": "^4.3.1",
    "flat": "^6.0.1",
    "hex-rgb": "^5.0.0",
    "i18n-iso-countries": "^7.10.0",
    "is-european": "^1.0.7",
    "locale-currency": "^0.0.2",
    "memoize-one": "^6.0.0",
    "nanoid": "^5.0.4",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-error-boundary": "^4.0.11",
    "react-google-recaptcha": "^3.1.0",
    "react-helmet-async": "^2.0.5",
    "react-router-dom": "^6.15.0",
    "snakecase-keys": "^8.0.1",
    "tailwind-merge": "^2.2.0",
    "tailwindcss": "^3.3.3"
  },
  "scripts": {}
}
