import React from 'react';

export function AmazonPayLogoImage ({
  className = ''
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="83" height="20" viewBox="0 0 83 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ className }}>
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M7.6418 10.8737C7.53604 10.9681 7.38375 10.9752 7.26529 10.9103C6.73508 10.4704 6.6406 10.2673 6.35152 9.84849C5.47722 10.7397 4.85676 11.0062 3.72441 11.0062C2.38335 11.0062 1.33984 10.1785 1.33984 8.52295C1.33984 7.22984 2.04069 6.34991 3.03907 5.91981C3.9035 5.54048 5.11058 5.47138 6.03423 5.36703V5.15974C6.03423 4.78041 6.06384 4.33198 5.84104 4.00483C5.64785 3.71152 5.27416 3.59024 4.94701 3.59024C4.33923 3.59024 3.79914 3.90189 3.66659 4.54633C3.63839 4.69016 3.53404 4.83259 3.3902 4.83964L1.84609 4.67183C1.71494 4.64222 1.57111 4.53787 1.60777 4.33903C1.96313 2.46636 3.6539 1.89948 5.16981 1.89948C5.94539 1.89948 6.95788 2.10536 7.56988 2.69339C8.34547 3.41821 8.27073 4.38275 8.27073 5.43472V7.91658C8.27073 8.66396 8.58096 8.99112 8.87145 9.39301C8.97298 9.53826 8.99696 9.71029 8.86722 9.81606C8.54148 10.0882 7.96332 10.5902 7.64603 10.8737H7.6418ZM6.03564 6.99012C6.03564 7.61058 6.05115 8.12811 5.7381 8.67947C5.48427 9.1279 5.08238 9.40429 4.63536 9.40429C4.02477 9.40429 3.66659 8.93894 3.66659 8.24797C3.66659 6.89141 4.88355 6.64463 6.03564 6.64463V6.99012Z" fill="#232F3E"/>
      <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M12.0048 10.8116H10.4113C10.259 10.8017 10.1392 10.6875 10.1265 10.5423L10.1279 2.36623C10.1279 2.20266 10.2647 2.07151 10.4353 2.07151H11.9188C12.0739 2.07856 12.198 2.19702 12.2078 2.34649V3.41398H12.2375C12.6238 2.38175 13.3529 1.89948 14.3344 1.89948C15.3313 1.89948 15.9546 2.38175 16.4016 3.41398C16.788 2.38175 17.6665 1.89948 18.6029 1.89948C19.2727 1.89948 20.0003 2.17445 20.4474 2.79492C20.9522 3.48307 20.8492 4.48146 20.8492 5.35857L20.8478 10.5183C20.8478 10.6819 20.711 10.813 20.5404 10.813H18.9498C18.789 10.8032 18.6635 10.6762 18.6635 10.5197V6.18492C18.6635 5.84084 18.6931 4.98065 18.6184 4.6535C18.4999 4.10213 18.1418 3.94842 17.682 3.94842C17.2957 3.94842 16.8938 4.20648 16.7302 4.61965C16.5666 5.03283 16.5807 5.72098 16.5807 6.18492V10.5197C16.5807 10.6833 16.4439 10.8144 16.2733 10.8144H14.6812C14.5219 10.8046 14.395 10.6777 14.395 10.5211L14.3936 6.18633C14.3936 5.27396 14.5416 3.93291 13.4121 3.93291C12.2671 3.93291 12.3122 5.24012 12.3122 6.18633V10.5211C12.3122 10.6805 12.1754 10.8116 12.0048 10.8116Z" fill="#232F3E"/>
      <path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M29.7597 9.3902C29.4692 8.98831 29.159 8.66116 29.159 7.91378V5.43191C29.159 4.37994 29.2337 3.41399 28.4581 2.69058C27.8475 2.10396 26.8336 1.89667 26.058 1.89667C24.5421 1.89667 22.8514 2.46214 22.496 4.33623C22.4579 4.53506 22.6032 4.63941 22.7343 4.66902L24.2784 4.83683C24.4223 4.82978 24.528 4.68735 24.5548 4.54352C24.6874 3.89767 25.2275 3.58744 25.8352 3.58744C26.1638 3.58744 26.5361 3.70871 26.7293 4.00202C26.9535 4.32918 26.9239 4.7776 26.9239 5.15693V5.36423C26.0002 5.46717 24.7917 5.53626 23.9287 5.91559C22.9303 6.3471 22.2295 7.22562 22.2295 8.52014C22.2295 10.1757 23.273 11.0034 24.6141 11.0034C25.7464 11.0034 26.3669 10.7355 27.2412 9.84568C27.5302 10.2645 27.6247 10.4676 28.1549 10.9075C28.2734 10.971 28.4257 10.9653 28.5315 10.8709L28.5357 10.8751C28.8544 10.5917 29.4325 10.0896 29.7569 9.81748C29.8838 9.7089 29.8612 9.53686 29.7597 9.3902ZM26.6235 8.67526C26.3711 9.12369 25.9678 9.40007 25.5208 9.40007C24.9102 9.40007 24.552 8.93472 24.552 8.24375C24.552 6.88719 25.769 6.64041 26.9211 6.64041V6.9859C26.9225 7.60636 26.9366 8.12248 26.6235 8.67526Z" fill="#232F3E"/>
      <path id="Vector_4" fillRule="evenodd" clipRule="evenodd" d="M31.1022 3.50421V2.33519C31.1036 2.15752 31.2375 2.03906 31.3983 2.03906H36.6356C36.8034 2.03906 36.9374 2.16034 36.9374 2.33378V3.3364C36.936 3.50421 36.7949 3.72419 36.5439 4.0725L33.8308 7.94477C34.8377 7.9208 35.9023 8.07168 36.8175 8.58639C37.0234 8.70202 37.0798 8.87406 37.0953 9.04187V10.2884C37.0953 10.4605 36.9078 10.6593 36.7089 10.555C35.0971 9.71028 32.9579 9.61862 31.1755 10.5662C30.9936 10.6635 30.8032 10.4675 30.8032 10.2955V9.11096C30.8032 8.92059 30.8075 8.59767 30.9978 8.30859L34.141 3.80034H31.4054C31.2375 3.79893 31.1036 3.67907 31.1022 3.50421Z" fill="#232F3E"/>
      <path id="Vector_5" fillRule="evenodd" clipRule="evenodd" d="M41.4585 3.56348C40.2839 3.56348 40.2091 5.164 40.2091 6.16097C40.2091 7.15936 40.1936 9.29292 41.443 9.29292C42.6783 9.29292 42.7361 7.57253 42.7361 6.52338C42.7361 5.83523 42.7065 5.00888 42.4978 4.35457C42.3201 3.78628 41.9633 3.56348 41.4585 3.56348ZM41.443 1.89386C43.8078 1.89386 45.0868 3.92448 45.0868 6.50505C45.0868 8.9996 43.6739 10.9795 41.443 10.9795C39.1233 10.9795 37.8584 8.94884 37.8584 6.42044C37.8598 3.8723 39.1388 1.89386 41.443 1.89386Z" fill="#232F3E"/>
      <path id="Vector_6" fillRule="evenodd" clipRule="evenodd" d="M48.1694 10.7947H46.5816C46.4222 10.7848 46.2953 10.6579 46.2953 10.5014L46.2925 2.32112C46.3066 2.17165 46.4377 2.05461 46.5985 2.05461H48.0763C48.2159 2.06166 48.3301 2.15614 48.3598 2.28305V3.53385H48.3894C48.8364 2.4156 49.4597 1.88257 50.561 1.88257C51.2745 1.88257 51.974 2.14063 52.4196 2.8457C52.8356 3.50001 52.8356 4.59993 52.8356 5.39243V10.5381C52.8172 10.6833 52.6875 10.7947 52.5296 10.7947H50.9347C50.7866 10.7848 50.6682 10.6763 50.6513 10.5381V6.09751C50.6513 5.20206 50.7556 3.89485 49.6543 3.89485C49.2679 3.89485 48.9111 4.15291 48.732 4.54916C48.5092 5.04835 48.4796 5.54755 48.4796 6.09751V10.5C48.4768 10.6636 48.3386 10.7947 48.1694 10.7947Z" fill="#232F3E"/>
      <path id="Vector_7" fillRule="evenodd" clipRule="evenodd" d="M61.1313 3.29696C60.3402 3.29696 59.5943 3.55361 58.8934 4.06549V8.72463C59.6055 9.25907 60.3402 9.52559 61.0989 9.52559C62.6345 9.52559 63.4031 8.49054 63.4031 6.41903C63.4017 4.33906 62.6444 3.29696 61.1313 3.29696ZM58.7764 3.0798C59.1994 2.71175 59.6507 2.43395 60.1301 2.24499C60.6082 2.05603 61.0989 1.96155 61.5995 1.96155C62.1339 1.96155 62.619 2.06167 63.0519 2.26191C63.4863 2.46215 63.8529 2.75123 64.1547 3.13056C64.4565 3.50848 64.6863 3.96819 64.8485 4.50828C65.0092 5.04837 65.091 5.65755 65.091 6.33724C65.091 7.02822 65.005 7.6515 64.8316 8.20851C64.6595 8.76552 64.417 9.23792 64.1053 9.62712C63.7937 10.0177 63.4144 10.3195 62.9702 10.5381C62.5245 10.7552 62.0296 10.8638 61.4839 10.8638C60.4812 10.8638 59.6182 10.5353 58.8948 9.87813V13.7857C58.8948 14.0085 58.7834 14.1199 58.5606 14.1199H57.5749C57.3521 14.1199 57.2407 14.0085 57.2407 13.7857V2.54535C57.2407 2.32255 57.3521 2.21114 57.5749 2.21114H58.3096C58.5324 2.21114 58.6607 2.32255 58.6946 2.54535L58.7764 3.0798Z" fill="#232F3E"/>
      <path id="Vector_8" fillRule="evenodd" clipRule="evenodd" d="M69.2555 9.64825C69.6221 9.64825 70.0071 9.57633 70.4076 9.43108C70.808 9.28584 71.193 9.07432 71.5596 8.79652V7.09306C71.2818 7.02678 70.9885 6.97319 70.6825 6.93371C70.3765 6.89423 70.079 6.87448 69.7885 6.87448C68.5306 6.87448 67.9017 7.35816 67.9017 8.32694C67.9017 8.74998 68.0188 9.07573 68.2514 9.30417C68.4869 9.53403 68.8211 9.64825 69.2555 9.64825ZM67.301 3.66921C67.2686 3.68049 67.2263 3.6946 67.1755 3.71152C67.1247 3.72844 67.0782 3.7369 67.0331 3.7369C66.8878 3.7369 66.8159 3.63678 66.8159 3.43654V2.98529C66.8159 2.83017 66.8385 2.72018 66.8822 2.65955C66.9273 2.59891 67.0161 2.53968 67.1501 2.48469C67.5069 2.32957 67.9454 2.20407 68.47 2.10959C68.9932 2.01511 69.5163 1.96716 70.0395 1.96716C71.0971 1.96716 71.8769 2.18433 72.3775 2.61724C72.8781 3.05157 73.1291 3.72562 73.1291 4.63799V10.3505C73.1291 10.5733 73.0177 10.6847 72.7949 10.6847H72.0772C71.8657 10.6847 71.743 10.5789 71.7091 10.3674L71.6259 9.81606C71.2254 10.1615 70.7827 10.4281 70.299 10.617C69.8153 10.806 69.333 10.9005 68.855 10.9005C68.054 10.9005 67.4194 10.6748 66.9513 10.2236C66.4831 9.77234 66.249 9.1688 66.249 8.41155C66.249 7.5993 66.5381 6.95063 67.1177 6.46554C67.6972 5.98186 68.4771 5.73931 69.4557 5.73931C70.079 5.73931 70.7812 5.83379 71.5596 6.02275V4.92002C71.5596 4.31788 71.4257 3.89484 71.1592 3.65088C70.8912 3.40552 70.4245 3.28283 69.7561 3.28283C68.9763 3.28565 68.1584 3.41398 67.301 3.66921Z" fill="#232F3E"/>
      <path id="Vector_9" fillRule="evenodd" clipRule="evenodd" d="M78.5651 12.164C78.2647 12.9325 77.9164 13.4839 77.5215 13.8181C77.1267 14.1523 76.612 14.3187 75.9774 14.3187C75.6207 14.3187 75.3034 14.2792 75.0256 14.2016C74.9029 14.1678 74.8141 14.1156 74.7577 14.0423C74.7012 13.9704 74.6744 13.8561 74.6744 13.6996V13.2653C74.6744 13.0538 74.7577 12.948 74.9255 12.948C74.9805 12.948 75.0636 12.9593 75.1765 12.9818C75.2879 13.0044 75.4373 13.0157 75.6277 13.0157C76.0508 13.0157 76.3793 12.9156 76.6134 12.7153C76.8475 12.5151 77.059 12.147 77.248 11.6126L77.5653 10.7609L74.3417 2.81044C74.2641 2.62148 74.2246 2.48752 74.2246 2.40996C74.2246 2.27599 74.3022 2.20972 74.4587 2.20972H75.4599C75.6376 2.20972 75.7603 2.23792 75.8279 2.29292C75.8942 2.34932 75.9619 2.472 76.0282 2.66096L78.3662 9.1406L80.621 2.66096C80.6873 2.472 80.755 2.34932 80.8213 2.29292C80.889 2.23792 81.0103 2.20972 81.1893 2.20972H82.1243C82.2794 2.20972 82.3584 2.2774 82.3584 2.40996C82.3584 2.48752 82.3189 2.62148 82.2413 2.81044L78.5651 12.164Z" fill="#232F3E"/>
      <path id="Vector_10" fillRule="evenodd" clipRule="evenodd" d="M33.2628 14.0183C30.2719 16.2252 25.9357 17.3984 22.203 17.3984C16.97 17.3984 12.2587 15.4637 8.69238 12.2443C8.41176 11.9919 8.66277 11.6464 8.99839 11.8424C12.8453 14.0817 17.6031 15.4298 22.5175 15.4298C25.8328 15.4298 29.4766 14.7417 32.8285 13.3202C33.3333 13.1059 33.7578 13.653 33.2628 14.0183ZM34.5066 12.5982C34.8887 13.089 34.0807 15.1069 33.7197 16.008C33.6097 16.2802 33.8452 16.3901 34.092 16.1843C35.6967 14.8418 36.1113 12.03 35.7828 11.6238C35.457 11.2219 32.6522 10.8736 30.9389 12.0765C30.6752 12.2612 30.7203 12.5165 31.0136 12.4812C31.9782 12.3656 34.1244 12.1075 34.5066 12.5982Z" fill="#232F3E"/>
    </svg>
  );
}
