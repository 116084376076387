import React from 'react';
export default {
  'cart': {
    'change-currency': 'Change currency',
    'and-then': 'And then',
    'plus-tax': '+ tax',
    'for': 'For',
    'edit': {
      'label': 'Edit quantities',
      'submit': 'Apply'
    },
    'empty': 'You haven’t added anything to your order yet.',
    'free': 'free',
    'promo-code': {
      'add': 'Add gift card or promo code',
      'label': 'Gift card or promo code',
      'submit': 'Apply'
    },
    'subtotal-line': {
      'tax-label': 'Estimated tax',
      'subtotal': 'Subtotal',
      'tax': 'Tax',
      'gift-card': 'Gift Card'
    },
    'starting-price': 'Starts at',
    'title': 'Order Summary',
    'setup-fee': 'setup fee',
    'free-trial': 'Free trial',
    'total-line': {
      'label': 'Total'
    },
    'order-total-line': {
      'label': 'Order Total'
    }
  },
  'address': {
    'country': 'Country',
    'address2': 'Apartment, suite, etc.',
    'tax-identifier': 'Tax Identifier',
    'tax-identifier-type': 'Tax Identifier type',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    'company-name': 'Company Name',
    'address1': 'Street Address',
    'city': 'City',
    'state': 'State',
    'postal-code': 'Zip/Postal',
    'phone-number': 'Phone Number',
    'vat-number': 'VAT Number/Tax ID'
  },
  'payment-method': {
    'credit-card': {
      'label': 'Card details',
      'expiry': 'MM/YY',
      'name': 'Credit Card',
      'number': 'Card Number',
      'cvv': 'CVV',
      'brand': {
        'visa': 'Visa',
        'master': 'MasterCard',
        'cartes_bancaires': 'Cartes Bancaires'
      }
    },
    'ach': {
      'name': 'Bank Account',
      'routing-number': 'Routing Number',
      'account-number': 'Account Number',
      'account-number-confirmation': 'Confirm Account Number',
      'account-type': {
        'label': 'Account Type',
        'checking': 'Checking',
        'savings': 'Savings'
      },
      'name-on-account': 'Name on Account'
    },
    'amazon': {
      'name': 'Amazon Pay'
    },
    'apple-pay': {
      'name': 'Apple Pay'
    },
    'boleto': {
      'name': 'Boleto'
    },
    'bacs': {
      'name': 'Bacs',
      'sort-code': 'Sort Code'
    },
    'becs': {
      'name': 'BECS',
      'bsb-code': 'BSB Code'
    },
    'google-pay': {
      'name': 'Google Pay'
    },
    'ideal': {
      'name': 'Ideal'
    },
    'paypal': {
      'name': 'PayPal'
    },
    'qiwi_wallet': {
      'name': 'Qiwi Wallet'
    },
    'sepa': {
      'name': 'SEPA',
      'iban': 'IBAN'
    },
    'sofort': {
      'name': 'Sofort'
    },
    'venmo': {
      'name': 'Venmo'
    }
  },
  'billing-address': 'Billing Address',
  'billing-address-same-as-shipping': 'Same as shipping address',
  'submit': 'Pay now',
  'payment-method-selected': ({ paymentMethod }) => <>{paymentMethod} selected</>,
  'express-checkout': 'Express Checkout',
  'change-payment-method': 'Change payment method',
  'day': 'day',
  'days': 'days',
  'week': 'week',
  'weeks': 'weeks',
  'month': 'month',
  'months': 'months',
  'year': 'year',
  'years': 'years',
  'confirmation': {
    'title': 'Thank you for your order',
    'order-num': ({ number }) => <>Order #{number}</>,
    'fulfilled-on': ({ date }) => <>Completed on {date}</>,
    'email-notice': 'You will receive an email confirmation of your order.',
    'payment': 'Payment',
    'return-to': ({ name }) => <>Return to {name}</>,
    'credit-applied': 'Credit Applied'
  },
  'back': 'Back',
  'contact-info': 'Contact Information',
  'email-address': 'Email',
  'payment-info': 'Billing Information',
  'shipping-address': 'Shipping Address',
  'legal': {
    'accept': 'I accept the',
    'privacy-policy': 'Privacy Policy',
    'terms-of-service': 'Terms of Service'
  },
  'support': {
    'array': {
      'wordsConnector': ', ',
      'twoWordsConnector': ' and ',
      'lastWordConnector': ', and '
    }
  },
  'powered-by': ({ recurly }) => <>Subscription billing powered by {recurly}</>,
  'errors': {},
  'subscribe': 'Subscribe'
};
