import countryNames from 'i18n-iso-countries';
import countryNamesForDa from 'i18n-iso-countries/langs/da.json';
import countryNamesForDe from 'i18n-iso-countries/langs/de.json';
import countryNamesForEn from 'i18n-iso-countries/langs/en.json';
import countryNamesForEs from 'i18n-iso-countries/langs/es.json';
import countryNamesForFr from 'i18n-iso-countries/langs/fr.json';
import countryNamesForHi from 'i18n-iso-countries/langs/hi.json';
import countryNamesForJa from 'i18n-iso-countries/langs/ja.json';
import countryNamesForNl from 'i18n-iso-countries/langs/nl.json';
import countryNamesForPt from 'i18n-iso-countries/langs/pt.json';
import countryNamesForRu from 'i18n-iso-countries/langs/ru.json';
import countryNamesForTr from 'i18n-iso-countries/langs/tr.json';
import countryNamesForZh from 'i18n-iso-countries/langs/zh.json';
import countryNamesForSv from 'i18n-iso-countries/langs/sv.json';
import countryNamesForPl from 'i18n-iso-countries/langs/pl.json';
import { flatten } from 'flat';

import da from './da';
import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import hi from './hi';
import ja from './ja';
import nl from './nl';
import pt from './pt';
import ru from './ru';
import tr from './tr';
import zh from './zh';
import sv from './sv';
import pl from './pl';

countryNames.registerLocale(countryNamesForDa);
countryNames.registerLocale(countryNamesForDe);
countryNames.registerLocale(countryNamesForEn);
countryNames.registerLocale(countryNamesForEs);
countryNames.registerLocale(countryNamesForFr);
countryNames.registerLocale(countryNamesForHi);
countryNames.registerLocale(countryNamesForJa);
countryNames.registerLocale(countryNamesForNl);
countryNames.registerLocale(countryNamesForPt);
countryNames.registerLocale(countryNamesForRu);
countryNames.registerLocale(countryNamesForTr);
countryNames.registerLocale(countryNamesForZh);
countryNames.registerLocale(countryNamesForSv);
countryNames.registerLocale(countryNamesForPl);

export const languageSupported = ({ language }: Intl.Locale) => language in translations;

export const translations = {
  da: flatten(da),
  de: flatten(de),
  en: flatten(en),
  es: flatten(es),
  fr: flatten(fr),
  hi: flatten(hi),
  ja: flatten(ja),
  nl: flatten(nl),
  pt: flatten(pt),
  ru: flatten(ru),
  tr: flatten(tr),
  zh: flatten(zh),
  sv: flatten(sv),
  pl: flatten(pl)
};

export const translatedCountryNameFor = (
  countryCode: string,
  langCode: string
) => (
  countryNames.getName(countryCode, langCode) || langCode
);
