import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { ApplePayLogoImage } from '../../images/apple-pay-logo';
import { Button } from '../../form';
import { ExpressCheckoutProps, PaymentMethodContext } from '../payment-methods';
import { PaymentMethod } from '@/services/session';
import { useCheckoutSession } from '@/hooks/use-checkout-session';
import { CheckoutAddress } from '../address-form';
import { ApplePayConfig } from '@recurly/recurly-js';

const usingBraintreeExclusively = ({
  gateways
}: PaymentMethod) => gateways.length === 1 && gateways[0].type === 'braintree_blue';

export function ApplePayButton ({ onError, onToken }: ExpressCheckoutProps) {
  const {
    billingAddress,
    paymentMethodForType,
    pricing,
    setBillingAddress,
    setPaymentMethod,
    setShippingAddress,
    shippingAddress
  } = useContext(PaymentMethodContext);
  const [canSubmit, setCanSubmit] = useState(true);
  const recurly = useRecurly();
  const [show, setShow] = useState(false);
  const [{
    cart: { currency }
  }] = useCheckoutSession();
  const applePayPaymentMethod = paymentMethodForType('apple_pay');

  // TODO
  //
  // In order to instantiate Apple Pay for Braintree, we need to
  // get the client authorization token from gateway configuration. This value
  // is not currently stored by Recurly.
  //
  // ref: braintree-client-auth
  if (applePayPaymentMethod && usingBraintreeExclusively(applePayPaymentMethod)) {
    return;
  }

  const applePay = useMemo(() => {
    if (!currency) return;
    if (!billingAddress?.country) return;

    const applePayConfig: ApplePayConfig = {
      country: billingAddress?.country,
      currency: currency.code,
      pricing,
      callbacks: {
        onPaymentMethodSelected: ({ paymentMethod: { billingContact } }) => {
          if (!billingContact) return;

          const newBillingAddress: CheckoutAddress = {
            city: billingContact.locality,
            country: billingContact.countryCode,
            postalCode: billingContact.postalCode,
            state: billingContact.administrativeArea
          };

          if (billingContact.addressLines) {
            newBillingAddress.address1 = billingContact.addressLines[0];
            newBillingAddress.address2 = billingContact.addressLines[1];
          }

          setBillingAddress({
            ...billingAddress,
            ...newBillingAddress
          });
        },
        onPaymentAuthorized: ({ payment: { recurlyToken } }) => {
          setCanSubmit(true);
          setPaymentMethod(applePayPaymentMethod);
          onToken(recurlyToken);
        },
        onShippingContactSelected ({ shippingContact }) {
          if (!shippingContact) return;

          const newShippingAddress: CheckoutAddress = {
            city: shippingContact.locality,
            country: shippingContact.countryCode,
            postalCode: shippingContact.postalCode,
            state: shippingContact.administrativeArea
          };

          if (shippingContact.addressLines) {
            newShippingAddress.address1 = shippingContact.addressLines[0];
            newShippingAddress.address2 = shippingContact.addressLines[1];
          }

          setShippingAddress({
            ...shippingAddress,
            ...newShippingAddress
          });
        },
      }
    };

    if (shippingAddress) {
      applePayConfig.requiredShippingContactFields = ['postalAddress'];
    }

    return recurly.ApplePay(applePayConfig);
  }, [currency, billingAddress?.country, pricing]);

  useEffect(() => {
    if (!applePay) return;

    applePay.ready(() => setShow(true));
    applePay.on('cancel', () => {
      setCanSubmit(true);
    });
    applePay.on('error', (error) => {
      if (error.code === 'apple-pay-not-supported') return;
      setCanSubmit(true);
      onError(error);
    });
  }, [applePay]);

  if (!show) return;

  return (
    <Button
      className="
      bg-black
        min-w-full min-h-12 py-2 px-4 mb-2
        rounded font-bold border-transparent
        hover:brightness-95 col-span-2
      "
      onClick={(event) => {
        event.preventDefault();
        setCanSubmit(false);
        applePay.begin();
      }}
      processing={!canSubmit}
    >
      <ApplePayLogoImage className="mx-auto" />
    </Button>
  );
}
